const Component = require('choo/component')
const html = require('choo/html')

const MapTooltip = require('./mapTooltip')

const heroData = require('../utilities/heroData')

const generateHeroHeader = (heroIds) => {
  if (!heroIds) {
    heroIds = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]
  }

  return html`
    <div class="clip-header flex pb2">
      <div class="clip-radiant mr2">
        ${heroIds.slice(0, 5).map(hero => {
          const imgClass = hero === -1 ? 'hero hero-empty' : `hero hero-${hero}`
          return html`
            <div class="dib">
              <div class="${imgClass}"></div>
              <div class="bar-parent relative">
                <div class="health-bar-bcg bg-dark-gray">
                  <div class="health-bar bg-red"></div>
                </div>
                <div class="mana-bar-bcg bg-dark-gray">
                  <div class="mana-bar bg-blue"></div>
                </div>
                <div class="death-timer-bcg tc bg-dark-gray dn">
                  <span class="v-top f6 washed-green"></span>
                </div>
              </div>
            </div>
          `
        })}
      </div>
      <div class="clip-radiant-info mr4 tl">
        <span class="clip-radiant-score f3 washed-green">0</span>
        <div class="clip-radiant-leading dn">
          <i class="icon-up-dir washed-green"></i>
          <span class="washed-green f6"></span>
        </div>
      </div>
      <div class="clip-dire-info ml4 mr2 tr">
        <span class="clip-dire-score f3 washed-green">0</span>
        <div class="clip-dire-leading dn">
          <i class="icon-up-dir washed-green"></i>
          <span class="washed-green f6"></span>
        </div>
      </div>
      <div class="clip-dire">
      ${heroIds.slice(5, 10).map(hero => {
        const imgClass = hero === -1 ? 'hero hero-empty' : `hero hero-${hero}`
        return html`
          <div class="dib">
            <div class="${imgClass}" ></div>
            <div class="bar-parent relative">
              <div class="health-bar-bcg bg-dark-gray">
                <div class="health-bar bg-red"></div>
              </div>
              <div class="mana-bar-bcg bg-dark-gray">
                <div class="mana-bar bg-blue"></div>
              </div>
              <div class="death-timer-bcg tc bg-dark-gray dn">
                <span class="v-top f6 washed-green"></span>
              </div>
            </div>
          </div>
        `
      })}
      </div>
    </div>
  `
}

const runeIcons = [133, 131, 132, 130, 134, 135, 136, 141]
const courierIcons = [127, 128]
const roshIcon = 129
const wardIcons = [137, 138]
const buildingIcons = [123, 124, 125, 126]

const CANVAS_SIZE = 600
const ICON_SIZE = 32
const BUILDING_SIZE = 16
const CREEP_SIZE = 8
const COURIER_SIZE = 24
const ROSH_SIZE = 16
const RUNE_SIZE = 22
const WARD_SIZE = 12
const WARD_RADIUS = 50
const SMOKE_RADIUS = 20

const LINE_WIDTH = 2

class MatchPlayer extends Component {
  constructor(id, state, emit) {
    super(id)
    this.state = state
    this.emit = emit

    this.animationInfo = {
      previous_s: -1,
      previous_ms: 0,
      current_time: 0
    }

    this.clipHeroInfo = []

    this.replayReceived = false

    this.sliderIsDragged = false
    this.paused = true
    this.mapImageLoaded = false
    this.iconImageLoaded = false

    this.canvasScale = 1
    this.state.match.canvasScale = 1

    this.playbackSpeed = 1

    this.correctSizeAndDraw = this.correctSizeAndDraw.bind(this)
    this.drawAtTime = this.drawAtTime.bind(this)
    this.animate = this.animate.bind(this)
    this.onSliderChange = this.onSliderChange.bind(this)
    this.onSliderEditStart = this.onSliderEditStart.bind(this)
    this.togglePause = this.togglePause.bind(this)
    this.changePlaybackSpeed = this.changePlaybackSpeed.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)

  }

  createElement() {
    this.summary = this.state.match.summary
    this.replay = this.state.match.replay
    this.animationInfo.current_time = 0
    this.animationInfo.previous_s = -1
    this.animationInfo.previous_ms = 0
    
    let clockText = '-1:30'
    if (this.state.match.replay.matchId) {
      this.replayReceived = true
      
      const gameTime = Math.floor(this.replay.startTime + this.animationInfo.current_time)
      
      const gameMinutes = Math.floor(Math.abs(gameTime) / 60)
      const gameSeconds = Math.abs(gameTime) % 60
      const sign = gameTime >= 0 ? '' : '-'
      
      clockText = gameSeconds >= 10 ? `${sign}${gameMinutes}:${gameSeconds}` : `${sign}${gameMinutes}:0${gameSeconds}`
    }

    
    return html`
      <div>
        ${generateHeroHeader(this.summary.heroIds)}
        <div class="fl w-100">
          <div class="relative">
            <canvas class="match-clip-canvas" width="600" height="600">
              Browser is not compatible with canvas.
            </canvas>
            ${this.state.cache(MapTooltip, 'tooltip-map').render(false, null)}
          </div>
          <div class="flex items-center justify-left">
            <i class="icon-play-circled washed-green pause-toggler mr2 f3"></i>
            <span class="washed-green game-clock mr2">${clockText}</span>
            <button class="playback-speed mr2 bg-washed-green ba b--black">1x</button>
            <input class="clip-slider mr2" type="range" min="0" max="100" value="0">
          </div>
        </div>
      </div>
    </div>`
  }

  update() {
    if (this.state.match.summary.matchId && this.state.match.summary.matchId !== this.summary.matchId && this.state.match.summary.matchId == this.state.params.matchId) {
      this.summary = this.state.match.summary
      this.clipHeroInfo = []
      const heroHeaderImages = this.element.getElementsByClassName("hero")
      for (let i = 0; i < 10; ++i) {
        const image = heroHeaderImages[i]
        image.className = `hero hero-${this.summary.heroIds[i]}`
      }
      this.summary.heroIds.forEach(id => {
        const heroIndex = heroData.findIndex(hero => hero.hero_id === id)
        if (heroIndex !== -1) {
          this.clipHeroInfo.push(heroData[heroIndex])
        } else {
          this.clipHeroInfo.push(heroData[0])
        }
      })
    }

    if (this.state.match.replay.matchId && this.state.match.replay.matchId !== this.replay.matchId && this.state.match.replay.matchId == this.state.params.matchId) {
      this.replay = this.state.match.replay
      this.replayReceived = true
      this.animationInfo.current_time = 0
      if (this.paused) {
        this.drawAtTime(this.animationInfo.current_time)
      }
    }

    if (this.state.match.requestedTime !== null) {

      this.animationInfo.current_time = this.state.match.requestedTime
      this.emit(this.state.events.timeRequestHandled)
      if (this.paused) {
        this.drawAtTime(this.animationInfo.current_time)
      }
    }


    return false
  }

  onKeyPress(event) {
    if (!this.replayReceived) return

    const key = event.which || event.keyCode
    switch(key) {
      case 119:
        this.playbackSpeed = this.playbackSpeed === 8 ? 1 : 2*this.playbackSpeed
        this.playbackSpeedButton.innerText = `${this.playbackSpeed}x`
        break
      case 115:
        this.playbackSpeed = this.playbackSpeed === 1 ? 8 : 0.5*this.playbackSpeed
        this.playbackSpeedButton.innerText = `${this.playbackSpeed}x`
        break
      case 13:
        this.togglePause()
        break
      case 97:
        this.animationInfo.current_time = Math.max(0, this.animationInfo.current_time - 30)
        this.animationInfo.first_ms = null
        if (this.paused) {
          this.drawAtTime(this.animationInfo.current_time)
        }
        break
      case 100:
        this.animationInfo.current_time = Math.min(this.animationInfo.current_time + 30, this.replay.endTime - this.replay.startTime)
        this.animationInfo.first_ms = null
        
        if (this.paused) {
          this.drawAtTime(this.animationInfo.current_time)
        }
        break
    }
  }

  load() {
    this.canvas = this.element.getElementsByClassName("match-clip-canvas")[0]
    this.ctx = this.canvas.getContext("2d")
    this.pauseToggler = this.element.getElementsByClassName("pause-toggler")[0]
    this.gameClock = this.element.getElementsByClassName("game-clock")[0]
    this.slider = this.element.getElementsByClassName("clip-slider")[0]

    this.healthBars = this.element.getElementsByClassName("health-bar")
    this.manaBars = this.element.getElementsByClassName("mana-bar")
    this.deathTimers = this.element.getElementsByClassName("death-timer-bcg")
    this.radiantScore = this.element.getElementsByClassName("clip-radiant-score")[0]
    this.direScore = this.element.getElementsByClassName("clip-dire-score")[0]
    this.radiantLeading = this.element.getElementsByClassName("clip-radiant-leading")[0]
    this.direLeading = this.element.getElementsByClassName("clip-dire-leading")[0]
    this.playbackSpeedButton = this.element.getElementsByClassName("playback-speed")[0]
    
    this.ctx.lineJoin = 'round'
    this.ctx.lineWidth = LINE_WIDTH

    this.minimapImage = new Image()
    this.minimapImage.src = '../assets/images/minimap_723.png'
    this.minimapImage.onload = () => {
      this.correctSizeAndDraw()
      this.mapImageLoaded = true
    }

    this.iconImage = new Image()
    this.iconImage.src = '../assets/images/minimap_icons.png'
    this.iconImage.onload = () => {
      this.iconImageLoaded = true
    }

    this.pauseToggler.addEventListener('click', this.togglePause)
    this.slider.addEventListener('mousedown', this.onSliderEditStart)
    this.slider.addEventListener('touchstart', this.onSliderEditStart)
    this.slider.addEventListener('change', this.onSliderChange)
    this.slider.addEventListener('input', this.onSliderChange)
    this.playbackSpeedButton.addEventListener('click', this.changePlaybackSpeed)
    window.addEventListener('resize', this.correctSizeAndDraw)
    window.addEventListener('orientationChange', this.correctSizeAndDraw)
    window.addEventListener('keypress', this.onKeyPress)
    window.requestAnimationFrame(this.animate)
  }

  unload() {
    this.pauseToggler.removeEventListener('click', this.togglePause)
    this.slider.removeEventListener('mousedown', this.onSliderEditStart)
    this.slider.removeEventListener('touchstart', this.onSliderEditStart)
    this.slider.removeEventListener('change', this.onSliderChange)
    this.slider.removeEventListener('input', this.onSliderChange)
    this.playbackSpeedButton.removeEventListener('click', this.changePlaybackSpeed)
    window.removeEventListener('keypress', this.onKeyPress)
    window.removeEventListener('resize', this.correctSizeAndDraw)
    window.removeEventListener('orientationChange', this.correctSizeAndDraw)
    if (!this.paused) {
      this.togglePause()
    }

  }

  onSliderChange(event) {
    if(this.replayReceived) {
      this.animationInfo.current_time = Math.floor(event.target.value / 100 * (this.replay.endTime - this.replay.startTime))
    }

    if (this.paused) {
      this.drawAtTime(this.animationInfo.current_time)
    }
  }

  changePlaybackSpeed(event) {
    this.playbackSpeed = this.playbackSpeed === 8 ? 1 : 2*this.playbackSpeed
    this.playbackSpeedButton.innerText = `${this.playbackSpeed}x`
    
  }

  onSliderEditStart(event) {
    if (!this.paused) {
      this.togglePause()
    }
  }

  togglePause(event) {
    this.paused = !this.paused

    if (this.paused) {
      this.pauseToggler.classList.add('icon-play-circled')
      this.pauseToggler.classList.remove('icon-pause-circle')
      this.animationInfo.first_ms = null
    } else {
      this.pauseToggler.classList.remove('icon-play-circled')
      this.pauseToggler.classList.add('icon-pause-circle')
      window.requestAnimationFrame(this.animate)
    }

  }

  drawAtTime(time) {
    if (!this.replayReceived) return

    time = Math.max(Math.min(time, this.replay.endTime - this.replay.startTime - 0.01), 0)
    
    const gameTime = Math.floor(this.replay.startTime + time)
    
    if (gameTime !== this.prevGameTime) {
      const gameMinutes = Math.floor(Math.abs(gameTime) / 60)
      const gameSeconds = Math.abs(gameTime) % 60
      const sign = gameTime >= 0 ? '' : '-'
      
      this.gameClock.innerText = gameSeconds >= 10 ? `${sign}${gameMinutes}:${gameSeconds}` : `${sign}${gameMinutes}:0${gameSeconds}`
      this.emit(this.state.events.timeChanged, Math.floor(time))
    }

    this.slider.value = 100 * time / (this.replay.endTime - this.replay.startTime)
    
    this.prevGameTime = gameTime

    const seconds = Math.floor(time)
    const lerp = time - seconds

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.ctx.drawImage(this.minimapImage, 0, 0, this.canvas.width, this.canvas.height)
    
    const previousSnap = this.replay.snapshots[seconds]
    const nextSnap = this.replay.snapshots[seconds + 1]

    for(let building in previousSnap.buildings) {
      let buildingIconIndex = 0
      switch(previousSnap.buildings[building].type) {
        case 0:
        case 1:
        case 3:
          buildingIconIndex = previousSnap.buildings[building].team === 2 ? 3 : 2
          break
        case 2:
          buildingIconIndex = previousSnap.buildings[building].team === 2 ? 1 : 0
          break
      }

      const xIndex = buildingIcons[buildingIconIndex] % 16
      const yIndex = Math.floor(buildingIcons[buildingIconIndex] / 16)

      const xPos = previousSnap.buildings[building].position[0] * CANVAS_SIZE
      const yPos = previousSnap.buildings[building].position[1] * CANVAS_SIZE

      if(nextSnap.buildings[building]) {
        if (nextSnap.buildings[building].type === 4) {
          switch(nextSnap.buildings[building].team) {
            case 2:
              this.ctx.fillStyle = '#00FF00'
              break
            case 3:
              this.ctx.fillStyle = '#df4b26'
              break
            case 4:
              this.ctx.fillStyle = '#111111'
          }
          this.ctx.strokeStyle = '#000000'
          this.ctx.fillRect((xPos - BUILDING_SIZE / 2)*this.canvasScale, (yPos - BUILDING_SIZE / 2)*this.canvasScale, BUILDING_SIZE*this.canvasScale, BUILDING_SIZE*this.canvasScale)
          this.ctx.strokeRect((xPos - BUILDING_SIZE / 2)*this.canvasScale, (yPos - BUILDING_SIZE / 2)*this.canvasScale, BUILDING_SIZE*this.canvasScale, BUILDING_SIZE*this.canvasScale)  
        } else {
          this.ctx.drawImage(this.iconImage, xIndex*ICON_SIZE, yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, (xPos - BUILDING_SIZE/2)*this.canvasScale, (yPos - BUILDING_SIZE/2)*this.canvasScale, BUILDING_SIZE*this.canvasScale, BUILDING_SIZE*this.canvasScale)
        }
      }
    }

    for(let creep in previousSnap.creeps) {
      if(nextSnap.creeps[creep]) {
        const prevX = previousSnap.creeps[creep].position[0]
        const prevY = previousSnap.creeps[creep].position[1]
        const nextX = nextSnap.creeps[creep].position[0]
        const nextY = nextSnap.creeps[creep].position[1]

        if (prevX >= 0 && prevY >= 0 && nextX >= 0 && nextY >= 0) {
          const xPos = ((1 - lerp)*prevX + lerp*nextX) * CANVAS_SIZE
          const yPos = ((1 - lerp)*prevY + lerp*nextY) * CANVAS_SIZE

          this.ctx.fillStyle = previousSnap.creeps[creep].team === 3 ? '#df4b26' : '#00FF00'
          this.ctx.strokeStyle = '#000000'
          this.ctx.fillRect((xPos - CREEP_SIZE / 2)*this.canvasScale, (yPos - CREEP_SIZE / 2)*this.canvasScale, CREEP_SIZE*this.canvasScale, CREEP_SIZE*this.canvasScale)
          this.ctx.strokeRect((xPos - CREEP_SIZE / 2)*this.canvasScale, (yPos - CREEP_SIZE / 2)*this.canvasScale, CREEP_SIZE*this.canvasScale, CREEP_SIZE*this.canvasScale)        
        }
        
      }
    }

    for(let rune in previousSnap.runes) {
      const xPos = previousSnap.runes[rune].position[0] * CANVAS_SIZE
      const yPos = previousSnap.runes[rune].position[1] * CANVAS_SIZE
      const iconIndex = runeIcons[previousSnap.runes[rune].type]
      const xIndex = iconIndex % 16
      const yIndex = Math.floor(iconIndex / 16)
      this.ctx.drawImage(this.iconImage, xIndex*ICON_SIZE, yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, (xPos - RUNE_SIZE/2)*this.canvasScale, (yPos - RUNE_SIZE/2)*this.canvasScale, RUNE_SIZE*this.canvasScale, RUNE_SIZE*this.canvasScale)
    }

    for(let courier in previousSnap.couriers) {
      if(nextSnap.couriers[courier]) {
        const prevX = previousSnap.couriers[courier].position[0]
        const prevY = previousSnap.couriers[courier].position[1]
        const nextX = nextSnap.couriers[courier].position[0]
        const nextY = nextSnap.couriers[courier].position[1]

        if (prevX >= 0 && prevY >= 0 && nextX >= 0 && nextY >= 0) {
          const xPos = ((1 - lerp)*prevX + lerp*nextX) * CANVAS_SIZE
          const yPos = ((1 - lerp)*prevY + lerp*nextY) * CANVAS_SIZE

          const iconIndex = previousSnap.couriers[courier].flying ? courierIcons[1] : courierIcons[0]
          const xIndex = iconIndex % 16
          const yIndex = Math.floor(iconIndex / 16)
          this.ctx.drawImage(this.iconImage, xIndex*ICON_SIZE, yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, (xPos - COURIER_SIZE/2)*this.canvasScale, (yPos - COURIER_SIZE/2)*this.canvasScale, COURIER_SIZE*this.canvasScale, COURIER_SIZE*this.canvasScale)
        }
      }
    }

    for(let ward in previousSnap.wards) {
      if(previousSnap.wards[ward].type === 0) {
        const xPos = previousSnap.wards[ward].position[0] * CANVAS_SIZE
        const yPos = previousSnap.wards[ward].position[1] * CANVAS_SIZE
        const iconIndex = previousSnap.wards[ward].team === 2 ? wardIcons[0] : wardIcons[1]
        const xIndex = iconIndex % 16
        const yIndex = Math.floor(iconIndex / 16)
        this.ctx.drawImage(this.iconImage, xIndex*ICON_SIZE, yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, (xPos - WARD_SIZE/2)*this.canvasScale, (yPos - WARD_SIZE/2)*this.canvasScale, WARD_SIZE*this.canvasScale, WARD_SIZE*this.canvasScale)
        this.ctx.strokeStyle = previousSnap.wards[ward].team === 3 ? '#df4b26' : '#00FF00'        
        this.ctx.beginPath()
        this.ctx.arc(xPos*this.canvasScale, yPos*this.canvasScale, WARD_RADIUS*this.canvasScale, 0, 2*Math.PI)
        this.ctx.stroke()
      }
    }

    if (previousSnap.roshan && nextSnap.roshan) {
      const prevX = previousSnap.roshan.position[0]
      const prevY = previousSnap.roshan.position[1]
      const nextX = nextSnap.roshan.position[0]
      const nextY = nextSnap.roshan.position[1]

      if (prevX >= 0 && prevY >= 0 && nextX >= 0 && nextY >= 0) {
        const xIndex = roshIcon % 16
        const yIndex = Math.floor(roshIcon / 16)
        const xPos = ((1 - lerp)*prevX + lerp*nextX) * CANVAS_SIZE
        const yPos = ((1 - lerp)*prevY + lerp*nextY) * CANVAS_SIZE
        this.ctx.drawImage(this.iconImage, xIndex*ICON_SIZE, yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, (xPos - ROSH_SIZE/2)*this.canvasScale, (yPos - ROSH_SIZE/2)*this.canvasScale, ROSH_SIZE*this.canvasScale, ROSH_SIZE*this.canvasScale)
      }
    }

    for(let i = 0; i < this.clipHeroInfo.length; ++i) {
      const xIndex = this.clipHeroInfo[i].icon_loc % 16
      const yIndex = Math.floor(this.clipHeroInfo[i].icon_loc / 16)

      const prevPlayer = previousSnap.players[i]
      const nextPlayer = nextSnap.players[i]

      if (prevPlayer.clones && nextPlayer.clones) {
        const prevAlpha = this.ctx.globalAlpha
        this.ctx.globalAlpha = 0.5
        for(let clone in prevPlayer.clones) {
          if(nextPlayer.clones[clone]) {
            const prevX = prevPlayer.clones[clone].position[0]
            const prevY = prevPlayer.clones[clone].position[1]
            const nextX = nextPlayer.clones[clone].position[0]
            const nextY = nextPlayer.clones[clone].position[1]

            if (prevX >= 0 && prevY >= 0 && nextX >= 0 && nextY >= 0) {
              const xPos = ((1 - lerp)*prevX + lerp*nextX) * CANVAS_SIZE
              const yPos = ((1 - lerp)*prevY + lerp*nextY) * CANVAS_SIZE
              this.ctx.drawImage(this.iconImage, xIndex*ICON_SIZE, yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, (xPos - ICON_SIZE/2)*this.canvasScale, (yPos - ICON_SIZE/2)*this.canvasScale, ICON_SIZE*this.canvasScale, ICON_SIZE*this.canvasScale)
            }
          }
        }
        this.ctx.globalAlpha = prevAlpha
      }
      
      const prevX = prevPlayer.position[0]
      const prevY = prevPlayer.position[1]
      const nextX = nextPlayer.position[0]
      const nextY = nextPlayer.position[1]
      if (prevX >= 0 && prevY >= 0 && nextX >= 0 && nextY >= 0) {
        this.deathTimers[i].classList.add('dn')
        this.deathTimers[i].classList.remove('db')
        const xPos = ((1 - lerp)*prevX + lerp*nextX) * CANVAS_SIZE
        const yPos = ((1 - lerp)*prevY + lerp*nextY) * CANVAS_SIZE
        this.ctx.drawImage(this.iconImage, xIndex*ICON_SIZE, yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, (xPos - ICON_SIZE/2)*this.canvasScale, (yPos - ICON_SIZE/2)*this.canvasScale, ICON_SIZE*this.canvasScale, ICON_SIZE*this.canvasScale)

        if (prevPlayer.smoked) {
          this.ctx.fillStyle = '#9e4fa8'
          this.ctx.globalAlpha = 0.5
          this.ctx.beginPath()
          this.ctx.arc(xPos*this.canvasScale, yPos*this.canvasScale, SMOKE_RADIUS*this.canvasScale, 0, 2*Math.PI)
          this.ctx.fill()
          this.ctx.globalAlpha = 1
        }
      } else {
        if (prevPlayer.respawnTimer && prevPlayer.respawnTimer >= 0) {
          this.deathTimers[i].classList.remove('dn')
          this.deathTimers[i].classList.add('db')
          this.deathTimers[i].children[0].innerText = Math.round(prevPlayer.respawnTimer)
        }
      }

      const hp = (1 - lerp)*prevPlayer.hp + lerp*nextPlayer.hp
      const mana = (1 - lerp)*prevPlayer.mana + lerp*nextPlayer.mana
      this.healthBars[i].style.width = `${Math.min(100*hp, 100)}%`
      this.manaBars[i].style.width = `${Math.min(100*mana, 100)}%`

    }

    this.radiantScore.innerText = previousSnap.radiantScore
    this.direScore.innerText = previousSnap.direScore

    const nwDiff = previousSnap.netWorthDifference
    const leadingText = `${Math.round(Math.abs(nwDiff) / 100) / 10}k`

    if (nwDiff > 0) {
      this.radiantLeading.classList.add('db')
      this.radiantLeading.classList.remove('dn')
      this.radiantLeading.children[1].innerText = leadingText

      this.direLeading.classList.remove('db')
      this.direLeading.classList.add('dn')
    } else {
      this.radiantLeading.classList.add('dn')
      this.radiantLeading.classList.remove('db')

      this.direLeading.classList.add('db')
      this.direLeading.classList.remove('dn')
      this.direLeading.children[1].innerText = leadingText
    }
  }

  animate(time) {
    if (!this.iconImageLoaded || !this.mapImageLoaded) {
      window.requestAnimationFrame(this.animate)
      return
    } else if(!this.replayReceived || this.clipHeroInfo.length !== 10) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.ctx.drawImage(this.minimapImage, 0, 0, this.canvas.width, this.canvas.height)
      return
    }
    
    if (this.paused) {
      this.drawAtTime(this.animationInfo.current_time)
      return
    }

    let progress = 0

    if (!this.animationInfo.first_ms) {
      this.animationInfo.first_ms = time
    } else {
      progress = (time - this.animationInfo.previous_ms) / 1000
    }

    this.animationInfo.previous_ms = time

    this.animationInfo.current_time += this.playbackSpeed*progress

    if(this.animationInfo.current_time >= this.replay.endTime - this.replay.startTime) {
      this.togglePause()
      return
    }

    this.drawAtTime(this.animationInfo.current_time)

    window.requestAnimationFrame(this.animate)
  }

  correctSizeAndDraw() {
    if(this.canvas.parentNode) {
      this.canvas.width = Math.min(CANVAS_SIZE, this.canvas.parentNode.clientWidth)
      this.canvas.height = this.canvas.width
      this.canvasScale = this.canvas.width / CANVAS_SIZE
      this.ctx.lineWidth = LINE_WIDTH * this.canvasScale
    } else {
      this.canvas.width = CANVAS_SIZE
      this.canvas.height = CANVAS_SIZE
      this.canvasScale = 1
      this.ctx.lineWidth = LINE_WIDTH
    }

    this.state.match.canvasScale = this.canvasScale

    if (this.paused && this.replayReceived && this.clipHeroInfo.length === 10) {
      this.drawAtTime(this.animationInfo.current_time)
    }
  }

}

module.exports = MatchPlayer