module.exports = (state, emitter) => {
  state.match = {
    matchId: 0,
    summary: {},
    replay: {},
    time: 0,
    requestedTime: null,
    matchChangeQueued: false,
    scoreboardType: 0
  }

  state.events = Object.assign({
    changeMatch: 'changeMatch',
    matchDataFetchError: 'matchDataFetchError',
    newMatchSummary: 'newMatchSummary',
    newMatchReplay: 'newMatchReplay',
    onReplayHeroSelected: 'replayHeroSelected',
    onReplayTimeRequest: 'replayTimeRequest',
    timeRequestHandled: 'timeRequestHandled',
    timeChanged: 'timeChanged',
    scoreboardTypeChanged: 'scoreboardTypeChanged'
  }, state.events)

  emitter.on(state.events.DOMCONTENTLOADED, () => {

    if(state.match.matchChangeQueued && state.params.matchId) {
      emitter.emit(state.events.changeMatch, state.params.matchId)
    }

  })

  emitter.on(state.events.scoreboardTypeChanged, type => {
    state.match.scoreboardType = type
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.onReplayHeroSelected, hero => {

  })

  emitter.on(state.events.onReplayTimeRequest, time => {
    state.match.requestedTime = time
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.timeRequestHandled, _ => {
    state.match.requestedTime = null
  })

  emitter.on(state.events.timeChanged, time => {
    state.match.time = time
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.newMatchSummary, summary => {
    state.match.summary = summary
    state.match.matchId = summary.matchId
    if (summary.radiantTeamName) {
      state.match.radiantTeamName = summary.radiantTeamName
    }

    if (summary.direTeamName) {
      state.match.direTeamName = summary.direTeamName
    }

    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.newMatchReplay, replay => {
    state.match.replay = replay
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.matchDataFetchError, error => {
    console.log(error)
  })

  emitter.on(state.events.changeMatch, matchId => {
    if (state.dataFetch) {

      if (state.match.matchId === matchId) return

      const existingMatch = state.matchPreparation.matchData.find(match => match.match_id == matchId)
      state.match.radiantTeamName = ""
      state.match.direTeamName = ""
      if (existingMatch) {
        state.match.matchId = matchId
        state.match.radiantTeamName = existingMatch.radiant_team_name
        state.match.direTeamName = existingMatch.dire_team_name

        emitter.emit(state.events.RENDER)
      }
      state.dataFetch.getMatchSummary(matchId, state, emitter)
      state.dataFetch.getMatchReplay(matchId, state, emitter)
    } else {
      state.match.matchChangeQueued = true
    }
    
  })

}