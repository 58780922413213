const endpoints = require('./endpoints')
const host = endpoints.api
const s3 = endpoints.s3

const getMatchSummary = (matchId, state, emitter) => {
  makeS3Request(`matches/${matchId}_events.json`, emitter, state.events.newMatchSummary, state.events.matchDataFetchError, 'Error in loading match events. Reload the page to try again.')
}

const getMatchReplay = (matchId, state, emitter) => {
  makeS3Request(`matches/${matchId}.json`, emitter, state.events.newMatchReplay, state.events.matchDataFetchError, 'Error in loading match replay. Reload the page to try again.')
}

const getMatchesByTeam = (teamId, state, emitter) => {
  makeRequest(`team/${teamId}/matches`, emitter, state.events.newTeamMatchData, state.events.dataFetchError, 'Error in loading data. Choose a team another time to try again.')
}

const getNewRadiantMatches = (teamId, state, emitter) => {
  makeRequest(`team/${teamId}/matches`, emitter, state.events.gotNewRadiantMatches, state.events.radiantDataFetchError, 'Error in loading data. Choose a team another time to try again.')  
}

const getNewDireMatches = (teamId, state, emitter) => {
  makeRequest(`team/${teamId}/matches`, emitter, state.events.gotNewDireMatches, state.events.direDataFetchError, 'Error in loading data. Choose a team another time to try again.')
}

const getHeroDataByTeam = (teamId, state, emitter) => {
  makeRequest(`heroes/${teamId}`, emitter, state.events.newTeamHeroData, state.events.dataFetchError, 'Error in loading data. Reload the page to try again.')
}

const getMatchesByLeague = (leagueId, state, emitter) => {
  makeRequest(`league/${leagueId}/matches`, emitter, state.events.newLeagueMatchData, state.events.dataFetchError, 'Error in loading data. Choose a team another time to try again.')
}

const getLeagueList = (state, emitter) => {
  makeRequest('leagues', emitter, state.events.newLeagueList, state.events.dataFetchError, 'Error in loading league data. Reload the page to try again')
}

const getTeamList = (state, emitter) => {
  makeRequest('teams', emitter, state.events.newTeamList, state.events.dataFetchError, 'Error in loading team data. Reload the page to try again.')
}

const makeRequest = (path, emitter, onSuccessEvent, onErrorEvent, errorText) => {
  fetch(`${host}${path}`)
  .then(response => {
    return response.json()
  })
  .then(json => {
    emitter.emit(onSuccessEvent, json)
  })
  .catch(ex => {
    emitter.emit(onErrorEvent, errorText)
  })
}

const makeS3Request = (path, emitter, onSuccessEvent, onErrorEvent, errorText) => {
  fetch(`${s3}${path}`)
  .then(response => {
    return response.json()
  })
  .then(json => {
    emitter.emit(onSuccessEvent, json)
  })
  .catch(ex => {
    emitter.emit(onErrorEvent, errorText)
  })
}

module.exports = {
  getMatchesByTeam: getMatchesByTeam,
  getHeroDataByTeam: getHeroDataByTeam,
  getTeamList: getTeamList,
  getLeagueList: getLeagueList,
  getMatchesByLeague: getMatchesByLeague,
  getNewRadiantMatches: getNewRadiantMatches,
  getNewDireMatches: getNewDireMatches,
  getMatchReplay: getMatchReplay,
  getMatchSummary: getMatchSummary
}