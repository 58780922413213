const Component = require('choo/component')
const html = require('choo/html')
const GraphTooltip = require('./graphTooltip')
const MapTooltip = require('./mapTooltip')

const tooltipName = 'tooltip-graph'
const CANVAS_SIZE = 600
const BACKGROUND_COLOR = '#333333'
const GOLD_COLOR = '#ffff00'
const REAL_GOLD_COLOR = '#FFFCEB'
const XP_COLOR = '#3399ff'
const BACKGROUND_LINE_WIDTH = 1
const GRAPH_LINE_WIDTH = 4
const Y_LEGEND_WIDTH = 32

class MatchGraphs extends Component {
  constructor(id, state, emit) {
    super(id)
    this.state = state
    this.emit = emit

    this.selectedMinute = -1
    this.summary = {}
    
    this.graphMaxDiff = 20000

    this.visibleEventType = ''
    this.visibleEvents = []

    this.correctSizeAndDraw = this.correctSizeAndDraw.bind(this)
    this.redrawCanvas = this.redrawCanvas.bind(this)
    this.updateSummaryStats = this.updateSummaryStats.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onClick = this.onClick.bind(this)
    this.onEventListChange = this.onEventListChange.bind(this)
  }

  onEventListChange(event) {
    this.visibleEventType = event.target.value
    if (this.summary.events) {
      this.visibleEvents = this.summary.events.filter(ev => ev.type === event.target.value)
    }
    this.redrawCanvas()
  }

  createElement() {
    this.summary = this.state.match.summary

    this.canvas = html`
      <canvas width="${CANVAS_SIZE}" height="${CANVAS_SIZE / 2}" id="graph-canvas">
        Browser is not compatible with canvas.
      </canvas>
    `
   
    return html`
      <div id="graph-parent" class="fl w-100">
        <div class="pb2">
          <input type="radio" name="graph-radio" value="PLAYER_KILL" checked> Kills
          <input class="ml2" type="radio" name="graph-radio" value="ITEM_PURCHASE"> Items
          <input class="ml2" type="radio" name="graph-radio" value="BUILDING_KILL"> Buildings
          <input class="ml2" type="radio" name="graph-radio" value="OUTPOST_OWNERSHIP"> Outposts
          <input class="ml2" type="radio" name="graph-radio" value="RUNE_USAGE"> Runes
          <input class="ml2" type="radio" name="graph-radio" value="WARD_PLACED"> Wards
          <input class="ml2" type="radio" name="graph-radio" value="SMOKE_USED"> Smokes
          <input class="ml2" type="radio" name="graph-radio" value="ROSHAN_KILL"> Roshan
        </div>
        <div class="flex">
          <div id="y-legends" class="relative" style="width: ${Y_LEGEND_WIDTH}px;">
          </div>
          <div class="flex flex-column">
            <div class="relative">
              ${this.canvas}
              ${this.state.cache(GraphTooltip, tooltipName).render(false, 0, 0, 0)}
            </div>
            <div id="time-legends" class="relative h2">
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div>
            <div style="background-color: #ffff00;" class="w1 h1 fl"></div>
            <span class="ml1">Gold</span>
          </div>
          <div class="ml2">
            <div style="background-color: #3399ff;" class="w1 h1 fl"></div>
            <span class="ml1">XP</span>
          </div>

        </div>
        
      </div>
    `
  }

  update() {
    if (this.summary.matchId !== this.state.match.summary.matchId) {
      this.summary = this.state.match.summary
      this.visibleEvents = this.summary.events.filter(ev => ev.type === this.visibleEventType)
      this.updateSummaryStats()
      this.redrawCanvas()
      this.redrawLegends()
      this.state.cache(GraphTooltip, tooltipName).render(false, 0, 0, 0, 0)
    }
    return false
  }

  updateSummaryStats() {
    if (this.summary.matchId) {
      const maxNWDiff = this.summary.netWorthGraph.reduce((max, current) => {
        if(Math.abs(current) > max) {
          return Math.abs(current)
        } else {
          return max
        }
      }, 0)

      const maxXPDiff = this.summary.xpGraph.reduce((max, current) => {
        if(Math.abs(current) > max) {
          return Math.abs(current)
        } else {
          return max
        }
      }, 0)

      const maxDiff = Math.max(maxXPDiff, maxNWDiff)
      this.graphMaxDiff = maxDiff + (5000 - (maxDiff % 5000))
    }
  }

  load() {
    this.ctx = this.canvas.getContext('2d')

    this.canvas.onmousemove = this.onMouseMove
    this.canvas.onmouseleave = this.onMouseLeave
    this.canvas.onclick = this.onClick

    this.correctSizeAndDraw()

    const radioButtons = document.getElementsByName("graph-radio")
    
    radioButtons.forEach(radio => {
      radio.addEventListener('change', this.onEventListChange)
      if (radio.checked) {
        this.onEventListChange({target: radio})
      }
    })

    window.addEventListener('resize', this.correctSizeAndDraw)
    window.addEventListener('orientationChange', this.correctSizeAndDraw)
  }

  onClick(event) {
    if (this.summary.duration) {

      const selectedSecond = Math.floor(event.offsetX / this.canvas.width * (this.summary.duration + 90))
      this.emit(this.state.events.onReplayTimeRequest, selectedSecond)
      this.state.cache(GraphTooltip, tooltipName).render(false, 0, 0, 0, 0)
      this.state.cache(MapTooltip, 'tooltip-map').render(false)
    }
  }

  onMouseMove(event) {
    if (!this.summary.matchId) return

    const maxTime = this.summary.duration + 90

    const minutes = Math.floor(maxTime / 60)
    const selectedPoint = event.offsetX / this.canvas.width
    const teamOfInterest = event.offsetY / this.canvas.height < 0.5 ? 2 : 3

    this.selectedMinute = Math.floor(selectedPoint * minutes)
    this.redrawCanvas()
    const gold = this.summary.netWorthGraph[Math.min(this.summary.netWorthGraph.length - 1, Math.floor(event.offsetX / this.canvas.width * this.summary.netWorthGraph.length))]
    const xp = this.summary.xpGraph[Math.min(this.summary.xpGraph.length - 1, Math.floor(event.offsetX / this.canvas.width * this.summary.xpGraph.length))]
    const time = Math.floor(event.offsetX / this.canvas.width * (this.summary.duration + 90))
    this.state.cache(GraphTooltip, tooltipName).render(true, time, gold, xp, event.offsetX)
    
    const closestEventIndex = this.visibleEvents.findIndex(ev => {
      return teamOfInterest === ev.team && Math.abs(ev.time - (-90 + selectedPoint*maxTime)) < 10 
    })

    if (closestEventIndex !== -1) {
      this.state.cache(MapTooltip, 'tooltip-map').render(true, this.visibleEvents[closestEventIndex])
    } else {
      this.state.cache(MapTooltip, 'tooltip-map').render(false, null)
    }
  }

  onMouseLeave(event) {
    this.selectedMinute = -1
    this.redrawCanvas()
    this.state.cache(GraphTooltip, tooltipName).render(false, 0, 0, 0, 0)
    this.state.cache(MapTooltip, 'tooltip-map').render(false)
  }

  unload() {
    const radioButtons = document.getElementsByName("graph-radio")
    radioButtons.forEach(radio => {
      radio.removeEventListener('change', this.onEventListChange)
    })

    window.removeEventListener('resize', this.correctSizeAndDraw)
    window.removeEventListener('orientationChange', this.correctSizeAndDraw)
  }

  correctSizeAndDraw() {
    const parent = document.getElementById('graph-parent')
    if(parent) {
      this.canvas.width = Math.min(CANVAS_SIZE, parent.clientWidth - Y_LEGEND_WIDTH)
      this.canvas.height = this.canvas.width * 0.5
      this.canvasScale = this.canvas.width / CANVAS_SIZE
    } else {
      this.canvas.width = CANVAS_SIZE
      this.canvas.height = CANVAS_SIZE * 0.5
      this.canvasScale = 1
    }
    this.redrawCanvas()
    this.redrawLegends()
  }

  redrawLegends() {
    const yLegends = document.getElementById('y-legends')
    yLegends.style.height = this.canvas.height
    while(yLegends.firstChild) {
      yLegends.removeChild(yLegends.lastChild)
    }

    const zeroLineY = this.canvas.height / 2
    const gapPerLine = (this.graphMaxDiff <= 20000 ? 5000 : 10000)
    const lineCountY = Math.ceil(this.graphMaxDiff / gapPerLine)
    const legendHeight = this.canvas.height * lineCountY / (this.graphMaxDiff / gapPerLine)

    for (let lineY = -lineCountY + 1; lineY < lineCountY; ++lineY) {
      const yLegend = document.createElement('span')
      yLegend.textContent = `${lineY * gapPerLine / 1000}k`
      yLegend.className = 'f6 washed-green absolute'
      const yCoordinate = Math.round(zeroLineY - lineY / lineCountY * legendHeight / 2)
      yLegend.style.top = `${yCoordinate - 7}px`

      yLegends.appendChild(yLegend)
    }

    const timeLegends = document.getElementById('time-legends')
    timeLegends.style.width = this.canvas.width
    while(timeLegends.firstChild) {
      timeLegends.removeChild(timeLegends.lastChild)
    }

    const zeroLineX = 90 / (this.summary.duration + 90) * this.canvas.width
    const minutesBetweenLines = this.summary.duration >= 3600 ? 10 : 5
    const secondsBetweenLines = minutesBetweenLines * 60

    const gapX = (this.canvas.width - zeroLineX) * secondsBetweenLines / this.summary.duration
    
    for (let lineX = 0; lineX*secondsBetweenLines < this.summary.duration; ++lineX) {
      const minuteLegend = document.createElement('span')
      minuteLegend.textContent = `${lineX*minutesBetweenLines}:00`
      minuteLegend.className = 'f6 washed-green absolute'
      const legendX = Math.round(zeroLineX + lineX*gapX) - minuteLegend.style.width / 2
      minuteLegend.style.left = `${legendX - 14}px`
      timeLegends.appendChild(minuteLegend)
    }
    
  }

  redrawCanvas() {
    this.ctx.lineWidth = BACKGROUND_LINE_WIDTH*this.canvasScale

    this.ctx.strokeStyle = BACKGROUND_COLOR
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.ctx.strokeRect(0, 0, this.canvas.width, this.canvas.height)

    const zeroLineX = 90 / (this.summary.duration + 90) * this.canvas.width
    const minutesBetweenLines = this.summary.duration >= 3600 ? 10 : 5
    const secondsBetweenLines = minutesBetweenLines * 60

    const gapX = (this.canvas.width - zeroLineX) * secondsBetweenLines / this.summary.duration

    for (let lineX = 0; lineX*secondsBetweenLines < this.summary.duration; ++lineX) {
      this.ctx.beginPath()
      this.ctx.moveTo(zeroLineX + lineX * gapX, 0)
      this.ctx.lineTo(zeroLineX + lineX * gapX, this.canvas.height)
      this.ctx.stroke()
    }

    const zeroLineY = this.canvas.height / 2
    const lineCountY = this.graphMaxDiff / (this.graphMaxDiff <= 20000 ? 5000 : 10000)

    this.ctx.beginPath()
    this.ctx.moveTo(0, zeroLineY)
    this.ctx.lineTo(this.canvas.width, zeroLineY)
    this.ctx.stroke()

    for (let lineY = 1; lineY < lineCountY; ++lineY) {
      this.ctx.beginPath()
      this.ctx.moveTo(0, zeroLineY + lineY / lineCountY * this.canvas.height / 2)
      this.ctx.lineTo(this.canvas.width, zeroLineY + lineY / lineCountY * this.canvas.height / 2)
      this.ctx.stroke()

      this.ctx.beginPath()
      this.ctx.moveTo(0, zeroLineY - lineY / lineCountY * this.canvas.height / 2)
      this.ctx.lineTo(this.canvas.width, zeroLineY - lineY / lineCountY * this.canvas.height / 2)
      this.ctx.stroke()
    }

    this.ctx.fillStyle = '#808000'
    if (this.selectedMinute !== -1) {
      const minutes = Math.floor((this.summary.duration + 90) / 60)
      this.ctx.fillRect(this.selectedMinute*this.canvas.width / minutes, 0, this.canvas.width / minutes, this.canvas.height)
    }

    this.ctx.lineWidth = GRAPH_LINE_WIDTH*this.canvasScale
    
    if(this.summary.netWorthGraph) {
      let i = 1

      this.ctx.strokeStyle = GOLD_COLOR
      this.ctx.beginPath()
      this.ctx.moveTo(0, this.canvas.height / 2)
      this.summary.netWorthGraph.forEach(point => {
        this.ctx.lineTo(i * this.canvas.width / this.summary.netWorthGraph.length, this.canvas.height / 2 - point / this.graphMaxDiff * this.canvas.height / 2)
        ++i
      })
      
      this.ctx.stroke()
    }
    
    if (this.summary.xpGraph) {
      let i = 1
      this.ctx.strokeStyle = XP_COLOR
      this.ctx.beginPath()
      this.ctx.moveTo(0, this.canvas.height / 2)
  
      this.summary.xpGraph.forEach(point => {
        this.ctx.lineTo(i * this.canvas.width / this.summary.xpGraph.length, this.canvas.height / 2 - point / this.graphMaxDiff * this.canvas.height / 2)
        ++i
      })
      this.ctx.stroke()
    }

    const MAX_TIME = this.summary.duration + 90

    this.visibleEvents.forEach(event => {
      const adjustedTime = event.time + 90
      const height = event.team === 2 ? 5 : this.canvas.height - 5
      this.ctx.fillStyle = event.team === 2 ? 'green' : 'red'
      this.ctx.beginPath()
      this.ctx.arc(adjustedTime / MAX_TIME * this.canvas.width, height, 5, 0, 2*Math.PI)
      this.ctx.fill()
    })
    
  }

}

module.exports = MatchGraphs