const Component = require('choo/component')
const html = require('choo/html')

const CANVAS_SIZE = 600
const ICON_SIZE = 32
const LINE_WIDTH = 5

class DrawableMap extends Component {
  constructor(id, state, emit) {
    super(id)

    this.drawings = []
    this.icons = []

    this.isPainting = false
    this.isUsingRedBrush = false
    this.canvasScale = 1
    this.movingIconIndex = -1

    this.addClick = this.addClick.bind(this)
    this.redrawCanvas = this.redrawCanvas.bind(this)
    this.correctSizeAndDraw = this.correctSizeAndDraw.bind(this)
    this.canvasMouseDown = this.canvasMouseDown.bind(this)
    this.canvasMouseMove = this.canvasMouseMove.bind(this)
    this.canvasMouseLeave = this.canvasMouseLeave.bind(this)
    this.canvasMouseUp = this.canvasMouseUp.bind(this)
    this.canvasTouchStart = this.canvasTouchStart.bind(this)
    this.canvasTouchEnd = this.canvasTouchEnd.bind(this)
    this.canvasTouchMove = this.canvasTouchMove.bind(this)
    this.canvasTouchCancel = this.canvasTouchCancel.bind(this)
    this.toggleBrushColor = this.toggleBrushColor.bind(this)
    this.removeDrawings = this.removeDrawings.bind(this)
    this.removeIcons = this.removeIcons.bind(this)
    this.addHeroIcon = this.addHeroIcon.bind(this)
    this.continueDrawing = this.continueDrawing.bind(this)
  }

  createElement() {

    this.canvas = html`
      <canvas width="${CANVAS_SIZE}" height="${CANVAS_SIZE}" id="map-canvas">
        Browser is not compatible with canvas.
      </canvas>
    `
    this.outputCanvasNode = html`<canvas width="${CANVAS_SIZE}" height="${CANVAS_SIZE}" id="output-canvas"></canvas>`

    this.canvas.onmousedown = this.canvasMouseDown
    this.canvas.onmousemove = this.canvasMouseMove
    this.canvas.onmouseup = this.canvasMouseUp
    this.canvas.onmouseleave = this.canvasMouseLeave
    this.canvas.ontouchstart = this.canvasTouchStart
    this.canvas.ontouchcancel = this.canvasTouchCancel
    this.canvas.ontouchmove = this.canvasTouchMove
    this.canvas.ontouchend = this.canvasTouchEnd

    return this.canvas
  }

  update() {
    return false
  }

  load() {
    this.ctx = this.canvas.getContext('2d')
    this.ctx.lineJoin = 'round'
    this.ctx.lineWidth = LINE_WIDTH

    this.minimapImage = new Image()
    this.minimapImage.src = '../assets/images/minimap_723.png'
    this.minimapImage.onload = () => {
      this.correctSizeAndDraw()
    }

    this.iconImage = new Image()
    this.iconImage.src = '../assets/images/minimap_icons.png'

    window.addEventListener('resize', this.correctSizeAndDraw)
    window.addEventListener('orientationChange', this.correctSizeAndDraw)
  }

  unload() {
    window.removeEventListener('resize', this.correctSizeAndDraw)
    window.removeEventListener('orientationchange', this.correctSizeAndDraw)
  }

  correctSizeAndDraw() {
    if(this.canvas.parentNode) {
      this.canvas.width = Math.min(CANVAS_SIZE, this.canvas.parentNode.clientWidth)
      this.canvas.height = this.canvas.width
      this.canvasScale = this.canvas.width / CANVAS_SIZE
      this.ctx.lineWidth = LINE_WIDTH * this.canvasScale
    } else {
      this.canvas.width = CANVAS_SIZE
      this.canvas.height = CANVAS_SIZE
      this.canvasScale = 1
      this.ctx.lineWidth = LINE_WIDTH
    }
    this.redrawCanvas()
  }

  toggleBrushColor() {
    this.isUsingRedBrush = !this.isUsingRedBrush
  }

  removeDrawings() {
    this.drawings.splice(0, this.drawings.length)
    this.redrawCanvas()
  }

  removeIcons() {
    this.icons.splice(0, this.icons.length)
    this.redrawCanvas()
  }

  addHeroIcon(xIndex, yIndex) {
    const putHeroToRadiantBase = Math.random() < 0.5
    let xPos, yPos
    const xRandom = Math.random()
    const yRandom = Math.random()
    if(putHeroToRadiantBase) {
      xPos = (0.05 + xRandom*0.15)*CANVAS_SIZE
      yPos = (0.92 - yRandom*0.15)*CANVAS_SIZE
    } else {
      xPos = (0.95 - xRandom*0.15)*CANVAS_SIZE
      yPos = (0.08 + yRandom*0.15)*CANVAS_SIZE
    }
    this.icons.push({
      xIndex : xIndex,
      yIndex : yIndex,
      xPos : xPos,
      yPos : yPos
    })
    this.redrawCanvas()
  }

  getDrawing() {
    const ctx = this.outputCanvasNode.getContext('2d')
    ctx.lineJoin = 'round'
    ctx.lineWidth = LINE_WIDTH

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.drawImage(this.minimapImage, 0, 0, ctx.canvas.width, ctx.canvas.height)

    this.drawings.forEach(drawing => {
      ctx.strokeStyle = drawing.withRedBrush ? '#df4b26' : '#00FF00'
      ctx.beginPath()
      ctx.moveTo(drawing.points[0], drawing.points[1])
      for(let i = 2; i < drawing.points.length - 1; i+=2) {
        ctx.lineTo(drawing.points[i], drawing.points[i+1])
      }
      ctx.stroke()
    })

    this.icons.forEach(icon => {
      ctx.drawImage(this.iconImage, icon.xIndex*ICON_SIZE, icon.yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, icon.xPos - ICON_SIZE/2, icon.yPos - ICON_SIZE/2, ICON_SIZE, ICON_SIZE)
    })

    return this.outputCanvasNode.toDataURL('image/png')
  }

  addClick(x, y, isDragging) {
    if(isDragging) {
      this.drawings[this.drawings.length - 1].points.push(x / this.canvasScale)
      this.drawings[this.drawings.length - 1].points.push(y / this.canvasScale)
    } else {
      this.drawings[this.drawings.length] = {points: [x / this.canvasScale, y / this.canvasScale], withRedBrush: this.isUsingRedBrush}
    }
  }

  canvasMouseDown(event) {
    if(event.button !== 0) return
    const iconIndex = this.whatIconWasHit(event.offsetX, event.offsetY)
    if(iconIndex !== -1) {
      this.movingIconIndex = iconIndex
    } else {
      this.isPainting = true
      this.addClick(event.offsetX, event.offsetY, false)
      this.redrawCanvas()
    }
  }

  canvasTouchStart(event) {
    const x = event.touches[0].pageX - event.target.offsetLeft
    const y = event.touches[0].pageY - event.target.offsetTop
    const iconIndex = this.whatIconWasHit(x, y)
    if(iconIndex !== -1) {
      this.movingIconIndex = iconIndex
    } else {
      this.isPainting = true
      this.addClick(x, y, false)
      this.redrawCanvas()
    }
  }

  canvasTouchMove(event) {
    const x = event.touches[0].pageX - event.target.offsetLeft
    const y = event.touches[0].pageY - event.target.offsetTop

    if(x < 0 || x > event.target.width || y < 0 || y > event.target.height) {
      this.isPainting = false
      if(this.movingIconIndex !== -1) {
        this.icons.splice(this.movingIconIndex, 1)
        this.redrawCanvas()
      }
      this.movingIconIndex = -1
    } else {
      if(this.isPainting) {
        this.addClick(x, y, true)
        this.redrawCanvas()
      } else if(this.movingIconIndex !== -1) {
        this.icons[this.movingIconIndex].xPos = x / this.canvasScale
        this.icons[this.movingIconIndex].yPos = y / this.canvasScale
        this.redrawCanvas()
      }
    }
  }
  canvasTouchEnd(event) {
    this.isPainting = false
    this.movingIconIndex = -1
  }
  canvasTouchCancel(event) {
    this.isPainting = false
    this.movingIconIndex = -1
  }

  whatIconWasHit(offsetX, offsetY) {
    return this.icons.findIndex(icon => {
      return Math.abs(icon.xPos*this.canvasScale - offsetX) < ICON_SIZE*this.canvasScale/2 && Math.abs(icon.yPos*this.canvasScale - offsetY) < ICON_SIZE*this.canvasScale/2
    })
  }

  canvasMouseMove(event) {
    if(this.isPainting) {
      this.addClick(event.offsetX, event.offsetY, true)
      this.continueDrawing()
    } else if(this.movingIconIndex !== -1) {
      this.icons[this.movingIconIndex].xPos = event.offsetX / this.canvasScale
      this.icons[this.movingIconIndex].yPos = event.offsetY / this.canvasScale
      this.redrawCanvas()
    }
  }

  canvasMouseUp(event) {
    this.isPainting = false
    this.movingIconIndex = -1
  }

  canvasMouseLeave(event) {
    this.isPainting = false
    if(this.movingIconIndex !== -1) {
      this.icons.splice(this.movingIconIndex, 1)
      this.redrawCanvas()
    }
    this.movingIconIndex = -1
  }

  redrawCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.ctx.drawImage(this.minimapImage, 0, 0, this.canvas.width, this.canvas.height)
    this.drawings.forEach(drawing => {
      this.ctx.strokeStyle = drawing.withRedBrush ? '#df4b26' : '#00FF00'
      this.ctx.beginPath()
      this.ctx.moveTo(drawing.points[0]*this.canvasScale, drawing.points[1]*this.canvasScale)
      for(let i = 2; i < drawing.points.length - 1; i+=2) {
        this.ctx.lineTo(drawing.points[i]*this.canvasScale, drawing.points[i+1]*this.canvasScale)
      }
      this.ctx.stroke()
    })

    this.icons.forEach(icon => {
      this.ctx.drawImage(this.iconImage, icon.xIndex*ICON_SIZE, icon.yIndex*ICON_SIZE, ICON_SIZE, ICON_SIZE, (icon.xPos - ICON_SIZE/2)*this.canvasScale, (icon.yPos - ICON_SIZE/2)*this.canvasScale, ICON_SIZE*this.canvasScale, ICON_SIZE*this.canvasScale)
    })
  }

  continueDrawing() {
    const drawing = this.drawings[this.drawings.length-1]
    this.ctx.lineTo(drawing.points[drawing.points.length-2]*this.canvasScale, drawing.points[drawing.points.length-1]*this.canvasScale)
    this.ctx.stroke()
  }
}

module.exports = DrawableMap