const Component = require('choo/component')
const html = require('choo/html')
const draftData = require('../utilities/draftData')

const getHeroUrl = (hero) => {
  const heroName = hero.name
  const imageName = heroName.substr("npc_dota_hero_".length)
  return "../assets/images/" + imageName + ".png"
}

class HeroRowImage extends Component {
  constructor(id, state, emit) {
    super(id);
    this.state = state
    this.emit = emit
  
    this.heroHasBeenPicked = this.heroHasBeenPicked.bind(this)
    this.selectHero = this.selectHero.bind(this)
  }

  heroHasBeenPicked() {
    return this.state.draftPractice.picks.findIndex(pick => pick.hero_id === this.hero.hero_id) !== -1
  }

  heroHasBeenFiltered() {
    return this.hero.localized_name.toLowerCase().indexOf(this.state.draftPractice.filterText.toLowerCase()) === -1
  }
  
  teamIsPicking(team) {
    const draftIsOver = this.state.draftPractice.picks.length === draftData.pickOrBan.length
    const teamDraftNum = (this.state.draftPractice.radiantStarts && team === 0) || (!this.state.draftPractice.radiantStarts && team === 1) ? 0 : 1
    return !draftIsOver && draftData.draftOrder[this.state.draftPractice.picks.length] === teamDraftNum
  }
  
  selectHero(event) {
    const heroId = parseInt(event.target.name)
    const order = this.state.draftPractice.picks.length
    const userIsPicking = !this.state.draftPractice.online || (this.teamIsPicking(this.state.draftPractice.userIsRadiant ? 0 : 1)) 
    if (userIsPicking && !this.heroHasBeenPicked(heroId) && order < draftData.draftOrder.length && this.hero.in_cm) {
      this.emit(this.state.events.selectHero, heroId)
    }
  }

  createElement(hero) {
    this.hero = hero
    const darkened = !hero.in_cm || this.heroHasBeenFiltered() || this.heroHasBeenPicked()
    const imageStyle = darkened ? "hero-box hero-box-picked" : "hero-box grow"
    
    return html`
      <div id="hero-list-${hero.hero_id}" class=${imageStyle}>
        <img src=${getHeroUrl(hero)} onclick=${this.selectHero} name=${hero.hero_id}/>
      </div>
    `
  }

  update(hero) {
    this.hero = hero
    const darkened = !hero.in_cm || this.heroHasBeenFiltered() || this.heroHasBeenPicked()
    if(darkened) {
      this.element.classList.add('hero-box-picked')
      this.element.classList.remove('grow')
    } else {
      this.element.classList.add('grow')
      this.element.classList.remove('hero-box-picked')
    }
    return false
  }
}

module.exports = HeroRowImage
