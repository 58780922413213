const Component = require('choo/component')
const html = require('choo/html')

class TextInput extends Component {
  constructor(id, state, emit) {
    super(id)
    this.emit = emit
    this.onInput = this.onInput.bind(this)
  }

  onInput(event) {
    this.emit(this.changeEvent, event.target.value)
  }

  createElement(initialText, currentValue, changeEvent) {
    this.changeEvent = changeEvent
    return html`
      <input type="text" class="text-input" placeholder=${initialText} oninput=${this.onInput} value=${currentValue}>
    `
  }

  update(initialText, currentValue, changeEvent) {
    this.changeEvent = changeEvent
    this.element.value = currentValue
    return false
  }
}

module.exports = TextInput