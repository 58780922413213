const Component = require('choo/component')
const html = require('choo/html')

class Footer extends Component {
  constructor(id) {
    super(id)
  }

  createElement() {
    return html`
      <footer class="fl pa4-l pa3-m pa1-ns">
        <p class="f6">
          Dota 2 is a registered trademark of Valve Corporation. This site is not affiliated with Valve Corporation. All images are property of Valve Corporation.
        </p>
        <p class="f6">
          Contact: info@dota2tools.com
        </p>
      </footer>
    `
  }

  update() {
    return false
  }

}

module.exports = Footer