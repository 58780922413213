const html = require('choo/html')
const Header = require('../components/header')
const Footer = require('../components/footer')
const MatchPlayer = require('../components/matchPlayer')
const MatchGraphs = require('../components/matchGraphs')
const ScoreboardRow = require('../components/scoreboardRow')

module.exports = (state, emit) => {

  const title = `Match ${state.params.matchId}`
  if (state.title !== title) {
    emit(state.events.DOMTITLECHANGE, title)
  }

  const matchParam = parseInt(state.params.matchId)
  if (!isNaN(matchParam) && state.match.summary.matchId !== matchParam) {
    emit(state.events.changeMatch, matchParam)
  }

  const onScoreboardTypeSelected = event => {
    emit(state.events.scoreboardTypeChanged, parseInt(event.target.value))
  }

  return html`
    <body class="bg-near-black pv1 ph1 ph2-ns ph3-m ph3-l">
      ${state.cache(Header, 'header').render()}
      <div class="w-100 pa4-l pa3-m pa1-ns washed-green">
        <h2 class="f4">Match ${state.params.matchId} - ${state.match.radiantTeamName} vs. ${state.match.direTeamName}</h1>
        <div class="fl w-100">
          <div class="fl w-100 w-50-l">
            ${state.cache(MatchPlayer, 'match-player').render()}
          </div>
          <div class="fl w-100 w-50-l pl2-l">
            ${state.cache(MatchGraphs, 'match-graphs').render()}
            <div class="fl w-100">
              <select class="db mv2" onchange=${onScoreboardTypeSelected}>
                <option value="0" selected=${state.match.scoreboardType === 0}>Items</option>
                <option value="1" selected=${state.match.scoreboardType === 1}>Scoreboard</option>
                <option value="2" selected=${state.match.scoreboardType === 2}>Net Worth</option>
              </select>
              <div class="flex w-100">
                <div class="w-50 mr2 f7">
                  ${[0,1,2,3,4].map(slot => state.cache(ScoreboardRow, `scoreboard-slot-${slot}`).render(slot, state.match.scoreboardType))}
                </div>
                <div class="w-50 f7">
                  ${[5,6,7,8,9].map(slot => state.cache(ScoreboardRow, `scoreboard-slot-${slot}`).render(slot, state.match.scoreboardType))}
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      ${state.cache(Footer, 'footer').render()}
    </body>
  `
}