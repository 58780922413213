const html = require('choo/html')

module.exports = function (state, emit) {
  const onTeamSelected = event => {
    if (event.target.value) {
      const teamId = parseInt(event.target.value)
      emit(state.events.prepareForTeam, teamId)
    }
  }

  const onEventSelected = event => {
    if(event.target.value) {
      const leagueId = parseInt(event.target.value)
      emit(state.events.prepareForEvent, leagueId)
    }
  }

  return html`
    <div class="pb2">
      <div>
        <h2 class="f4">Find drafts of a specific team ...</h2>
        <select onchange=${onTeamSelected}>
          <option value="-1" selected=${state.matchPreparation.teamId === -1}>Select Team</option>
          ${state.general.teamList.map(team => {
            return html`<option value=${team.team_id} selected=${state.matchPreparation.teamId == team.team_id}>${team.name}</option>`
          })}
        </select>
      </div>
      <div>
        <h2 class="f4">... or look through games of a recent event</h2>
        <select onchange=${onEventSelected}>
          <option value="-1" selected=${state.matchPreparation.leagueId === -1}>Select League</option>
          ${state.general.leagueList.map(league => {
            return html`<option value=${league.league_id} selected=${state.matchPreparation.leagueId == league.league_id}>${league.name}</option>`
          })}
        </select>
      </div>
    </div>
  `
}