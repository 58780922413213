const heroData = require('./heroData')

const getHeroName = (heroId) => {
  const index = heroData.findIndex(hero => hero.hero_id == heroId)
  if (index == -1) {
    return 'Unknown'
  }
  return heroData[index].localized_name
}

const getHeroUrl = (heroId) => {
  const index = heroData.findIndex(hero => hero.hero_id == heroId)
  if (index == -1) {
    return '../assets/images/default.png'
  }
  const heroName = heroData[index].name
  const imageName = heroName.substr("npc_dota_hero_".length)
  return `../assets/images/${imageName}.png`
}

module.exports = {
  getHeroName : getHeroName,
  getHeroUrl : getHeroUrl
}