const Component = require('choo/component')
const html = require('choo/html')

const CANVAS_SIZE = 600

class MapTooltip extends Component {
  constructor(id, state, emit) {
    super(id)
    this.state = state
    this.emit = emit
    this.visible = false
  }

  createElement(visible, event) {
    this.visible = visible
    if(!visible) {
      return html`<div class="absolute dn"></div>`
    }

    const time = Math.round(event.time)

    let minutes = Math.floor(Math.abs(time) / 60)
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    if (time < 0) {
      minutes = `-${minutes}`
    }
    
    let seconds = Math.abs(time) % 60
    if (seconds < 10) {
      seconds = `0${seconds}`
    }
    const timeDisplay = `${minutes}:${seconds}`

    const x = Math.round(event.x * CANVAS_SIZE * this.state.match.canvasScale)
    const y = Math.round(event.y * CANVAS_SIZE * this.state.match.canvasScale)

    switch (event.type) {
      case "SMOKE_USED":
        return html`
          <div class="ba b--washed-green bg-near-black absolute" style="left:${x}px;top:${y}px;z-index: 1;">
            <div class="f5 pa2">
              <p>Time: ${timeDisplay}</p>
              <p>${event.team === 2 ? "Radiant" : "Dire"} used a smoke</p>
            </div>
            <div class="triangle-topleft"></div>
          </div>
        `
      case "WARD_PLACED":
        return html`
          <div class="ba b--washed-green bg-near-black absolute" style="left:${x}px;top:${y}px;z-index: 1;">
            <div class="f5 pa2">
              <p>Time: ${timeDisplay}</p>
              <p>${event.team === 2 ? "Radiant" : "Dire"} placed a ward</p>
            </div>
            <div class="triangle-topleft"></div>
          </div>
        `
      case "ITEM_PURCHASE":
        return html`
          <div class="ba b--washed-green bg-near-black absolute" style="left:${x}px;top:${y}px;z-index: 1;">
            <div class="f5 pa2">
              <p>Time: ${timeDisplay}</p>
              <div class="flex items-center">
              <div class="hero hero-${this.state.match.summary.heroIds[event.player]} mr2"></div>
              <span>purchased</span>
              <img class="scoreboard-item ml2" src="/assets/images/items/${event.itemName}.png" />
            </div>
            </div>
            <div class="triangle-topleft"></div>
          </div>
        `
      case "PLAYER_KILL":
        return html`
          <div class="ba b--washed-green bg-near-black absolute" style="left:${x}px;top:${y}px;z-index: 1;">
            <div class="f5 pa2">
              <p>Time: ${timeDisplay}</p>
              <div class="flex items-center">
                <div class="hero hero-${this.state.match.summary.heroIds[event.player]} mr2"></div>
                <span>died</span>
              </div>
            </div>
            <div class="triangle-topleft"></div>
          </div>
        `
      case "BUILDING_KILL":
        return html`
          <div class="ba b--washed-green bg-near-black absolute" style="left:${x}px;top:${y}px;z-index: 1;">
            <div class="f5 pa2">
              <p>Time: ${timeDisplay}</p>
              <p>${event.team === 2 ? "Radiant" : "Dire"} destroyed a building</p>
            </div>
            <div class="triangle-topleft"></div>
          </div>
        `
      case "RUNE_USAGE":
        let runeName = ''
        switch(event.runeId) {
          case 0:
            runeName = 'doubledamage'
            break
          case 1:
            runeName = 'haste'
            break
          case 2:
            runeName = 'illusion'
            break
          case 3:
            runeName = 'invis'
            break
          case 4:
            runeName = 'regen'
            break
          case 6:
            runeName = 'arcane'
            break
        }

        return html`
          <div class="ba b--washed-green bg-near-black absolute" style="left:${x}px;top:${y}px;z-index: 1;">
            <div class="f5 pa2">
              <p>Time: ${timeDisplay}</p>
              <div class="flex items-center">
                <div class="hero hero-${this.state.match.summary.heroIds[event.player]} mr2"></div>
                <span>used</span>
                <div class="hero rune-${runeName} ml2"></div>
              </div>
            </div>
            <div class="triangle-topleft"></div>
          </div>
        `
      case "ROSHAN_KILL":
        return html`
          <div class="ba b--washed-green bg-near-black absolute" style="left:${x}px;top:${y}px;z-index: 1;">
            <div class="f5 pa2">
              <p>Time: ${timeDisplay}</p>
              <p>${event.team === 2 ? "Radiant" : "Dire"} killed roshan</p>
            </div>
            <div class="triangle-topleft"></div>
          </div>
        `
      case "OUTPOST_OWNERSHIP":
        return html`
          <div class="ba b--washed-green bg-near-black absolute" style="left:${x}px;top:${y}px;z-index: 1;">
            <div class="f5 pa2">
              <p>Time: ${timeDisplay}</p>
              <p>${event.team === 2 ? "Radiant" : "Dire"} captured an outpost</p>
            </div>
            <div class="triangle-topleft"></div>
          </div>
        `
    }

    return html`<div class="absolute dn"></div>`
  }

  update(visible, event) {
    return true
  }
}

module.exports = MapTooltip