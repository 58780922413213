const draftData = require('../utilities/draftData')
const endpoints = require('../utilities/endpoints')
let ws = null

const createDraftObject = _ => ({
  roomId: -1,
  radiantStarts: true,
  timerOn: false,
  paused: false,
  picks: [],
  currentPickTime: draftData.pickTimes.ban,
  radiantExtraTime: draftData.maxBonusTime,
  direExtraTime: draftData.maxBonusTime,
  usersConnected: 1,
  lastEventType: ''
})

const heroData = require('../utilities/heroData')

module.exports = (state, emitter) => {
  state.draftPractice = {
    userIsRadiant: true,
    timerOn: false,
    paused: false,
    online: false,
    roomName: '',
    radiantStarts: true,
    selectedHeroId: -1,
    picks: [],
    filterText: '',
    timerInterval: null,
    radiantExtraTime: draftData.maxBonusTime,
    direExtraTime: draftData.maxBonusTime,
    currentPickTime: draftData.pickTimes.ban,
    draftObject: createDraftObject(),
    errorText: '',
    radiantTeamId: -1,
    radiantRecentMatches: [],
    radiantStats: {},
    direTeamId: -1,
    direRecentMatches: [],
    direStats: {}
  }

  state.events = Object.assign({
    restartDraft: 'restartDraft',
    selectHero: 'selectHero',
    pickHero: 'pickHero',
    undoLastPick: 'undoLastPick',
    filterTextChange: 'filterTextChange',
    timerToggle: 'timerToggle',
    pauseToggle: 'pauseToggle',
    connectToRoom: 'connectToRoom',
    disconnectFromRoom: 'disconnectFromRoom',
    roomKeyTextChange: 'roomKeyTextChange',
    radiantTeamChanged: 'radiantTeamChanged',
    direTeamChanged: 'direTeamChanged',
    gotNewRadiantMatches: 'gotNewRadiantMatches',
    gotNewDireMatches: 'gotNewDireMatches',
    radiantDataFetchError: 'radiantDataFetchError',
    direDataFetchError: 'direDataFetchError'

  }, state.events)

  emitter.on(state.events.selectHero, heroId => {
    state.draftPractice.selectedHeroId = heroId
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.radiantTeamChanged, teamId => {
    state.draftPractice.radiantTeamId = teamId
    state.dataFetch.getNewRadiantMatches(teamId, state, emitter)
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.direTeamChanged, teamId => {
    state.draftPractice.direTeamId = teamId
    state.dataFetch.getNewDireMatches(teamId, state, emitter)
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.gotNewRadiantMatches, matches => {
    state.draftPractice.radiantRecentMatches = matches
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.gotNewDireMatches, matches => {
    state.draftPractice.direRecentMatches = matches
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.radiantDataFetchError, error => {

  })

  emitter.on(state.events.direDataFetchError, error => {

  })

  const isDraftOver = () => {
    return state.draftPractice.picks.length === draftData.draftOrder.length
  }

  emitter.on(state.events.pickHero, pick => {
    state.draftPractice.selectedHeroId = -1
    if(!isDraftOver() && (!state.draftPractice.online || (radiantIsPicking() === state.draftPractice.userIsRadiant))) {
      state.draftPractice.picks.push(pick)
      state.draftPractice.filterText = ''
      state.draftPractice.currentPickTime = draftData.pickTimes.pick
      updateDraftObject(state.events.pickHero)
      emitter.emit(state.events.RENDER)
    }
  })

  emitter.on(state.events.restartDraft, draftSettings => {
    state.draftPractice.selectedHeroId = -1
    state.draftPractice.picks = []
    state.draftPractice.radiantStarts = draftSettings.radiantStarts
    state.draftPractice.radiantExtraTime = draftData.maxBonusTime
    state.draftPractice.direExtraTime = draftData.maxBonusTime
    state.draftPractice.currentPickTime = draftData.pickTimes.ban
    state.draftPractice.paused = false

    updateDraftObject(state.events.restartDraft)
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.undoLastPick, _ => {
    state.draftPractice.picks.selectedHeroId = -1
    state.draftPractice.picks.pop()
    state.draftPractice.currentPickTime = draftData.pickTimes.pick
    if(state.draftPractice.picks.length < 2) {
      if(radiantIsPicking()) {
        state.draftPractice.radiantExtraTime = draftData.maxBonusTime
      } else {
        state.draftPractice.direExtraTime = draftData.maxBonusTime
      }
    }
    updateDraftObject(state.events.undoLastPick)
    emitter.emit(state.events.RENDER)
  })

  const initializeWebSocket = roomName => {
    ws = new WebSocket(endpoints.ws)

    ws.addEventListener('open', event => {
      ws.send(JSON.stringify({
        request: 'connect',
        roomName: roomName,
        draft: state.draftPractice.draftObject
      }))
    })

    // Listen for messages
    ws.addEventListener('message', event => {
      if(!event.data) return

      try {
        const message = JSON.parse(event.data)

        switch(message.request)
        {
          case 'roomCreated':
            state.draftPractice.userIsRadiant = true
            state.draftPractice.online = true
            state.draftPractice.draftObject = message.draft
            updateBasedOnDraftObject()
            break
          case 'roomJoined':
            state.draftPractice.userIsRadiant = message.side === 'radiant'
            state.draftPractice.online = true
            state.draftPractice.draftObject = message.draft
            updateBasedOnDraftObject()
            break
          case 'update':
            state.draftPractice.draftObject = message.draft
            state.draftPractice.online = true
            updateBasedOnDraftObject()
            break
        }
      } catch(err) {
        state.draftPractice.errorText = 'Problem connecting to the server.'
        state.draftPractice.online = false
        state.draftPractice.userIsRadiant = true
        state.draftPractice.draftObject = createDraftObject()
        emitter.emit(state.events.RENDER)
      }
      
    })

    ws.addEventListener('error', error => {
      state.draftPractice.errorText = 'Problem connecting to the server.'
      state.draftPractice.online = false
      state.draftPractice.userIsRadiant = true
      emitter.emit(state.events.RENDER)
    })

    ws.addEventListener('close', event => {
      state.draftPractice.online = false
      state.draftPractice.userIsRadiant = true
      
      emitter.emit(state.events.RENDER)
    })
  }

  const updateDraftObject = (event) => {
    Object.assign(state.draftPractice.draftObject, {
      picks: state.draftPractice.picks,
      radiantExtraTime: state.draftPractice.radiantExtraTime,
      direExtraTime: state.draftPractice.direExtraTime,
      timerOn: state.draftPractice.timerOn,
      paused: state.draftPractice.paused,
      radiantStarts: state.draftPractice.radiantStarts,
      lastEventType: event,
      currentPickTime: state.draftPractice.currentPickTime
    })

    if(state.draftPractice.online && ws) {
      ws.send(JSON.stringify({
        request: 'update',
        roomId: state.draftPractice.draftObject.roomId,
        draft: state.draftPractice.draftObject
      }))

    }
  }

  const updateBasedOnDraftObject = () => {
    state.draftPractice.picks = state.draftPractice.draftObject.picks
    state.draftPractice.radiantExtraTime = state.draftPractice.draftObject.radiantExtraTime
    state.draftPractice.direExtraTime = state.draftPractice.draftObject.direExtraTime
    if(state.draftPractice.draftObject.lastEventType !== state.events.pauseToggle) {
      state.draftPractice.currentPickTime = draftData.pickTimes.pick
    } else {
      state.draftPractice.currentPickTime = state.draftPractice.draftObject.currentPickTime
    }
    state.draftPractice.paused = state.draftPractice.draftObject.paused
    if(state.draftPractice.timerOn !== state.draftPractice.draftObject.timerOn) {
      state.draftPractice.timerOn = state.draftPractice.draftObject.timerOn
      setupTimer()
    }

    state.draftPractice.radiantStarts = state.draftPractice.draftObject.radiantStarts
    emitter.emit(state.events.RENDER)
  }

  const setupTimer = () => {
    if(state.draftPractice.timerOn) {
      state.draftPractice.timerInterval = setInterval(handleTimer, 1000)
    } else {
      clearInterval(state.draftPractice.timerInterval)
    }
  }

  emitter.on(state.events.filterTextChange, text => {
    state.draftPractice.filterText = text
    emitter.emit(state.events.RENDER)
  })

  const radiantIsPicking = () => {
    return (draftData.draftOrder[state.draftPractice.picks.length] === 0 && state.draftPractice.radiantStarts) || (draftData.draftOrder[state.draftPractice.picks.length] === 1 && !state.draftPractice.radiantStarts) 
  }

  const makeRandomPick = team => {
    const order = state.draftPractice.picks.length
    if(order === draftData.pickOrBan.length) return

    emitter.emit(state.events.pickHero, {
      hero_id: getRandomUnpickedHero(),
      is_pick: draftData.pickOrBan[order] === 1,
      order: order,
      team: team
    })
  }

  const getRandomUnpickedHero = () => {
    const allowedHeroes = heroData.filter(hero => {
      return state.draftPractice.picks.findIndex(pick => pick.hero_id === hero.hero_id) === -1
    })
    const heroCount = allowedHeroes.length
    return allowedHeroes[Math.floor(heroCount*Math.random())].hero_id
  }

  emitter.on(state.events.pauseToggle, () => {
    state.draftPractice.paused = !state.draftPractice.paused
    updateDraftObject(state.events.pauseToggle)
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.timerToggle, () => {
    state.draftPractice.timerOn = !state.draftPractice.timerOn
    updateDraftObject(state.events.timerToggle)
    setupTimer()
    emitter.emit(state.events.RENDER)
  })

  const handleTimer = () => {
    if(!state.draftPractice.timerOn || state.draftPractice.paused || state.draftPractice.picks.length === draftData.pickOrBan.length) return
    
    const radiantPicking = radiantIsPicking()
    const userIsPicking = !state.draftPractice.online || (radiantPicking === state.draftPractice.userIsRadiant)
    
    if(state.draftPractice.currentPickTime > 0) {
      state.draftPractice.currentPickTime--
      emitter.emit(state.events.RENDER)
    } else {
      if(radiantPicking) {
        if(state.draftPractice.radiantExtraTime === 0) {
          if(userIsPicking) makeRandomPick(0)
        } else {
          state.draftPractice.radiantExtraTime--
          emitter.emit(state.events.RENDER)
        }
      } else {
        if(state.draftPractice.direExtraTime === 0) {
          if(userIsPicking) makeRandomPick(1)
        } else {
          state.draftPractice.direExtraTime--
          emitter.emit(state.events.RENDER)
        }
      }
    }
  }

  emitter.on(state.events.roomKeyTextChange, text => {
    state.draftPractice.roomName = text
  })

  emitter.on(state.events.disconnectFromRoom, () => {
    if(ws) {
      ws.close()
      ws = null
      state.draftPractice.online = false
      emitter.emit(state.events.RENDER)
    }

  })

  emitter.on(state.events.connectToRoom, () => {
    if(!state.draftPractice.roomName) {
      state.draftPractice.errorText = 'Enter a room name!'
      emitter.emit(state.events.RENDER)
      return
    }

    state.draftPractice.errorText = ''

    initializeWebSocket(state.draftPractice.roomName)

  })
}