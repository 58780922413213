const html = require('choo/html')
const matchDisplay = require('../utilities/matchDisplay')

const MATCHES_PER_PAGE = 30

let matchPageCount = 1

module.exports = function (state, emit) {

  const goToPreviousPage = event => {
    if(state.matchPreparation.matchListPage > 0) {
      emit(state.events.changePage, -1)
    }
  }

  const goToNextPage = event => {
    if(state.matchPreparation.matchListPage < matchPageCount - 1) {
      emit(state.events.changePage, 1)
    }
  }

  const getPagingRow = () => (
    html`
      <div>
        <button class="ma2 f6 ph1 ph1-ns ph3-m ph3-l pv1 pv1-ns pv2-m pv2-l dib black bg-white" onclick=${goToPreviousPage}>Previous</button>
        <span class="ma2">Page ${state.matchPreparation.matchListPage+1} of ${matchPageCount}</span>
        <button class="ma2 f6 ph1 ph1-ns ph3-m ph3-l pv1 pv1-ns pv2-m pv2-l dib black bg-white" onclick=${goToNextPage}>Next</button>
      </div>
    `
  )

  const filteredMatchContent = () => {
    matchPageCount = Math.max(1, Math.ceil(state.matchPreparation.filteredMatchData.length / MATCHES_PER_PAGE))
    return state.matchPreparation.filteredMatchData.slice(state.matchPreparation.matchListPage*MATCHES_PER_PAGE, (state.matchPreparation.matchListPage + 1)*MATCHES_PER_PAGE).map((match) => matchDisplay.getMatchRow(match))
  }

  return html`
    <div class="match-list">
      <h2 class="f4">Match List</h2>
      <div class="tc">
        <div class="dt tl w-100 f5">
          <div class="dt-row">
            <span class="dtc pa2 v-mid">Match Info</span>
            <span class="dn dtc-l pa2 v-mid">League</span>
            <span class="dtc pa2 v-mid">Radiant</span>
            <span class="dtc pa2 v-mid">Dire</span>
            <span class="dn dtc-l pa2 v-mid">Score</span>
          </div>
          ${filteredMatchContent()}
        </div>
        ${getPagingRow()}
      </div>
    </div>
  `
}