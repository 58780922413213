const constraintTypes = require('./constraints')

const LAST_WEEK = 60*60*24*7
const LAST_MONTH = 60*60*24*30
const LAST_3_MONTHS = 3*LAST_MONTH
const LAST_YEAR = 12*LAST_MONTH

const getFilteredLeagueMatches = (matchData, constraints) => {
  const filteredData = matchData.filter(match => {
    return constraints.every(constraint => {
      if(!constraint.active) return true

      switch(constraint.constraintType) {
        case constraintTypes.league.withHeroInMatch:
          return matchIncludes(match, constraint.constraintData.heroId, constraint.constraintData.pickType, constraint.constraintData.pickPhase)
        case constraintTypes.league.withHeroRadiant:
          return teamHeroesInclude(match, 0, constraint.constraintData.heroId, constraint.constraintData.pickType, constraint.constraintData.pickPhase)
        case constraintTypes.league.withHeroDire:
          return teamHeroesInclude(match, 1, constraint.constraintData.heroId, constraint.constraintData.pickType, constraint.constraintData.pickPhase)
        case constraintTypes.league.onPatch:
          return constraint.constraintData.patch === 'any' || match.patch === constraint.constraintData.patch
        case constraintTypes.league.includesTeam:
          return constraint.constraintData.teamId === -1 || match.radiant_team_id === constraint.constraintData.teamId || match.dire_team_id === constraint.constraintData.teamId
        case constraintTypes.league.result:
          return constraint.constraintData.result === -1 || (constraint.constraintData.result === 0 && match.radiant_win) || (constraint.constraintData.result === 1 && !match.radiant_win) 
        case constraintTypes.league.pickOrder:
          return constraint.constraintData.order === -1 || (constraint.constraintData.order === 0 && match.picksandbans[0].team === 0) || (constraint.constraintData.order === 1 && match.picksandbans[0].team === 1)
      }
      return true
    })
  })
  return filteredData
}

const getFilteredTeamMatches = (teamId, matchData, constraints) => {
  const filteredData = matchData.filter(match => {
    const team = match.radiant_team_id === teamId ? 0 : 1
    const otherTeam = 1 - team
    const otherTeamId = team === 0 ? match.dire_team_id : match.radiant_team_id

    return constraints.every(constraint => {
      if(!constraint.active) return true

      switch(constraint.constraintType) {
        case constraintTypes.team.heroInMatch:
          return matchIncludes(match, constraint.constraintData.heroId, constraint.constraintData.pickType, constraint.constraintData.pickPhase)
        case constraintTypes.team.againstHero:
          return teamHeroesInclude(match, otherTeam, constraint.constraintData.heroId, constraint.constraintData.pickType, constraint.constraintData.pickPhase)
        case constraintTypes.team.withHero:
          return teamHeroesInclude(match, team, constraint.constraintData.heroId, constraint.constraintData.pickType, constraint.constraintData.pickPhase)
        case constraintTypes.team.againstTeam:
          return constraint.constraintData.teamId === -1 || otherTeamId === constraint.constraintData.teamId
        case constraintTypes.team.atEvent:
          return constraint.constraintData.leagueId === -1 || match.league_id === constraint.constraintData.leagueId
        case constraintTypes.team.onPatch:
          return constraint.constraintData.patch === 'any' || match.patch === constraint.constraintData.patch
        case constraintTypes.team.onSide:
          return constraint.constraintData.side === -1 || (constraint.constraintData.side === 0 && match.radiant_team_id === teamId) || (constraint.constraintData.side === 1 && match.dire_team_id === teamId)
        case constraintTypes.team.result:
          return constraint.constraintData.result === -1 || (constraint.constraintData.result === 0 && ((match.radiant_team_id === teamId && match.radiant_win) || (match.dire_team_id === teamId && !match.radiant_win))) || (constraint.constraintData.result === 1 && ((match.radiant_team_id === teamId && !match.radiant_win) || (match.dire_team_id === teamId && match.radiant_win)))
        case constraintTypes.team.pickOrder:
          return constraint.constraintData.order === -1 || (constraint.constraintData.order === 0 && teamIsFirstPick(match, teamId)) || (constraint.constraintData.order === 1 && !teamIsFirstPick(match, teamId))
        case constraintTypes.team.matchTime:
          return satisfiesTimeConstraint(constraint.constraintData.time, match.start_time)
      }
      return true
    })
  })
  
  return filteredData
}

const satisfiesTimeConstraint = (optionValue, startTime) => {
  const gapSeconds = Date.now() / 1000 - startTime
  return optionValue === -1 ||
    (optionValue === 0 && gapSeconds < LAST_WEEK) ||
    (optionValue === 1 && gapSeconds < LAST_MONTH) ||
    (optionValue === 2 && gapSeconds < LAST_3_MONTHS) ||
    (optionValue === 3 && gapSeconds < LAST_YEAR)
}

const teamIsFirstPick = (match, teamId) => {
  const teamIsRadiant = match.radiant_team_id === teamId
  return (teamIsRadiant && match.picksandbans[0].team === 0) || (!teamIsRadiant && match.picksandbans[0].team === 1) 
}

const teamHeroesInclude = (match, team, heroId, pickType, phase) => {
  const limits = getPhaseLimits(match, phase)
  return heroId === -1 || match.picksandbans.some(pick => {
    return pick.hero_id === heroId && (pickType === -1 || (pickType === 0) === pick.is_pick)
      && pick.team === team && pick.ord >= limits[0] && pick.ord <= limits[1]
  })
}

const matchIncludes = (match, heroId, pickType, phase) => {
  const limits = getPhaseLimits(match, phase)
  return heroId === -1 || match.picksandbans.some(pick => {
    return pick.hero_id === heroId && (pickType === -1 || (pickType === 0) === pick.is_pick)
      && pick.ord >= limits[0] && pick.ord <= limits[1]
  })
}

const getPhaseLimits = (match, phase) => {
  if(phase === -1) {
    return [0, match.picksandbans.length]
  }

  let start = -1
  let end = -1
  let currentPhase = -1
  let lastWasPick = true

  for(let i = 0; i < match.picksandbans.length; ++i)
  {
    if(match.picksandbans[i].is_pick !== lastWasPick) {
      ++currentPhase

      if(currentPhase > 2+2*phase) {
        break
      }
      lastWasPick = match.picksandbans[i].is_pick
    }

    if(currentPhase >= 2*phase && currentPhase < 2 + 2*phase) {
      if(start === -1) {
        start = i
      } else {
        end = i
      }
    }
  }

  return [start, end]
}

const createHeroStatsForTeam = (matchData, teamId) => {
  const heroStats = {}
  
  const summarizedHeroStats = {
    matchCount: matchData.length,
    winCount: 0,
    commonPicks: [],
    commonBans: [],
    commonFirstPhasePicks: [],
    commonEnemyPicks: [],
    bestHeroes: [],
    bestAgainst: [],
    worstAgainst: [],
    flexPicks: []
  }

  matchData.forEach(match => {
    const team = teamId === match.radiant_team_id ? 0 : 1
    const teamWon = (match.radiant_win && team === 0) || (!match.radiant_win && team === 1)
    if(teamWon) summarizedHeroStats.winCount += 1
    
    match.picksandbans.forEach(pick => {
      const firstPhaseLimits = getPhaseLimits(match, 0)
      if(!heroStats[pick.hero_id]) heroStats[pick.hero_id] = {heroId: pick.hero_id, picksWith:0, bansWith:0, winsWith:0, phaseOneWith: 0, picksAgainst: 0, winsAgainst: 0, bansAgainst: 0, players: {}, playerCount: 0}
      
      if(pick.team === team) {
        if(pick.is_pick) {
          heroStats[pick.hero_id].picksWith += 1
  
          if(!heroStats[pick.hero_id].players[pick.account_id]) {
            heroStats[pick.hero_id].players[pick.account_id] = true
            heroStats[pick.hero_id].playerCount += 1
          }
          
          if(teamWon) heroStats[pick.hero_id].winsWith += 1
          if(pick.ord >= firstPhaseLimits[0] && pick.ord <= firstPhaseLimits[1]) heroStats[pick.hero_id].phaseOneWith += 1
        } else {
          heroStats[pick.hero_id].bansWith += 1
        }
      } else {
        if(pick.is_pick) {
          heroStats[pick.hero_id].picksAgainst += 1
  
          if(teamWon) heroStats[pick.hero_id].winsAgainst += 1
        } else {
          heroStats[pick.hero_id].bansAgainst += 1
        }
      }
      
    })

  })
  const heroes = Object.entries(heroStats)
  summarizedHeroStats.commonPicks = heroes.sort((hero1, hero2) => hero2[1].picksWith - hero1[1].picksWith).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.commonBans = heroes.sort((hero1, hero2) => hero2[1].bansWith - hero1[1].bansWith).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.commonFirstPhasePicks = heroes.sort((hero1, hero2) => hero2[1].phaseOneWith - hero1[1].phaseOneWith).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.commonEnemyPicks = heroes.sort((hero1, hero2) => hero2[1].picksAgainst - hero1[1].picksAgainst).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.bestHeroes = heroes.sort((hero1, hero2) => {
    const wr1 = hero1[1].picksWith > 0 ? hero1[1].winsWith / hero1[1].picksWith : 0
    const wr2 = hero2[1].picksWith > 0 ? hero2[1].winsWith / hero2[1].picksWith : 0
    return wr2 - wr1
  }).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.bestAgainst = heroes.sort((hero1, hero2) => {
    const wr1 = hero1[1].picksAgainst > 0 ? hero1[1].winsAgainst / hero1[1].picksAgainst : 0
    const wr2 = hero2[1].picksAgainst > 0 ? hero2[1].winsAgainst / hero2[1].picksAgainst : 0
    return wr2 - wr1
  }).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.worstAgainst = heroes.sort((hero1, hero2) => {
    const wr1 = hero1[1].picksAgainst > 0 ? hero1[1].winsAgainst / hero1[1].picksAgainst : 0
    const wr2 = hero2[1].picksAgainst > 0 ? hero2[1].winsAgainst / hero2[1].picksAgainst : 0
    return wr1 - wr2
  }).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.flexPicks = heroes.filter(hero => hero[1].playerCount > 1).sort((hero1, hero2) => hero2[1].picksWith - hero1[1].picksWith).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.stats = heroStats

  return summarizedHeroStats
}

const createHeroStatsForLeague = (matchData) => {
  const heroStats = {}

  const summarizedHeroStats = {
    matchCount: matchData.length,
    commonPicks: [],
    commonBans: [],
    commonFirstPhasePicks: [],
    bestHeroes: []
  }

  matchData.forEach(match => {
    match.picksandbans.forEach(pick => {
      const firstPhaseLimits = getPhaseLimits(match, 0)
      if(!heroStats[pick.hero_id]) heroStats[pick.hero_id] = {heroId: pick.hero_id, picks:0, bans:0, wins:0, phaseOne: 0}
      if(pick.is_pick) {
        heroStats[pick.hero_id].picks += 1
        if((match.radiant_win && pick.team === 0) || (!match.radiant_win && pick.team === 1)) heroStats[pick.hero_id].wins += 1
        if(pick.ord >= firstPhaseLimits[0] && pick.ord <= firstPhaseLimits[1]) heroStats[pick.hero_id].phaseOne += 1
      } else {
        heroStats[pick.hero_id].bans += 1
      }
    })
  })

  const heroes = Object.entries(heroStats)
  summarizedHeroStats.commonPicks = heroes.sort((hero1, hero2) => hero2[1].picks - hero1[1].picks).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.commonBans = heroes.sort((hero1, hero2) => hero2[1].bans - hero1[1].bans).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.commonFirstPhasePicks = heroes.sort((hero1, hero2) => hero2[1].phaseOne - hero1[1].phaseOne).slice(0, 5).map((hero) => hero[1].heroId)
  summarizedHeroStats.bestHeroes = heroes.sort((hero1, hero2) => {
    const wr1 = hero1[1].picks > 0 ? hero1[1].wins / hero1[1].picks : 0
    const wr2 = hero2[1].picks > 0 ? hero2[1].wins / hero2[1].picks : 0
    return wr2 - wr1
  }).slice(0, 5).map((hero) => hero[1].heroId)

  summarizedHeroStats.stats = heroStats

  return summarizedHeroStats
}

module.exports = {
  getFilteredTeamMatches: getFilteredTeamMatches,
  getFilteredLeagueMatches: getFilteredLeagueMatches,
  createHeroStatsForTeam: createHeroStatsForTeam,
  createHeroStatsForLeague: createHeroStatsForLeague
}