const html = require('choo/html')
const title = 'Dota 2 Tools'

module.exports = (state, emit) => {
  if (state.title !== title) emit(state.events.DOMTITLECHANGE, title)
  return html`
    <body class="bg-near-black">
      <div class="vh-100 dt w-100">
        <div class="dtc v-mid tc ph3 ph4-l">
          <h1 class="link dim washed-green b f-headline-ns tc db mb3 mb4-ns">Dota 2 Tools</h1>
          <div class="tc pb3">
            <a class="link dim washed-green f3-ns dib mr3" href="/mapdrawing">Map Drawing</a>
            <a class="link dim washed-green f3-ns dib mr3" href="/draft">Draft Practice</a>
            <a class="link dim washed-green f3-ns dib mr3" href="/matches">Matches</a>
          </div>
        </div>
      </div>
    </body>
  `
}