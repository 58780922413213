const html = require('choo/html')
const Header = require('../components/header')
const Footer = require('../components/footer')

const title = '404 - Dota 2 Tools'

module.exports = (state, emit) => {
  if (state.title !== title) emit(state.events.DOMTITLECHANGE, title)
  return html`
    <body class="bg-near-black pv1 ph1 ph2-ns ph3-m ph3-l">
      ${state.cache(Header, 'header').render()}
      <div class="fl w-100 pa4-l pa3-m pa1-ns">
        <h3 class="f4">
          Page not found
        </h3>
      </div>
      ${state.cache(Footer, 'footer').render()}
    <body class="bg-near-black pv1 ph1 ph2-ns ph3-m ph3-l">
  `
}