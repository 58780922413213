module.exports = (state, emitter) => {
  state.general = {}
  state.general.leagueList = []
  state.general.teamList = []
  state.general.teamListQueued = false
  state.general.leagueListQueued = false

  state.events = Object.assign({
    newLeagueList: 'newLeagueList',
    fetchNewLeagueList: 'fetchNewLeagueList',
    newTeamList: 'newTeamList',
    fetchNewTeamList: 'fetchNewTeamList'
  }, state.events)

  emitter.on(state.events.DOMCONTENTLOADED, () => {
    state.dataFetch = require('../utilities/dataFetch')

    if(state.general.teamListQueued) {
      state.dataFetch.getTeamList(state, emitter)
    }

    if(state.general.leagueListQueued) {
      state.dataFetch.getLeagueList(state, emitter)
    }

  })

  emitter.on(state.events.fetchNewTeamList, () => {
    if(state.dataFetch && !state.general.teamListQueued) {
      state.dataFetch.getTeamList(state, emitter)
    }
    state.general.teamListQueued = true

  })

  emitter.on(state.events.fetchNewLeagueList, () => {
    if (state.dataFetch && !state.general.leagueListQueued) {
      state.dataFetch.getLeagueList(state, emitter)
    }
    state.general.leagueListQueued = true
  })

  emitter.on(state.events.newLeagueList, data => {
    if(!data || data.length === 0) {
      emitter.emit(state.events.dataFetchError, 'Error in loading data. Reload the page to try again.')
      return
    }
    state.general.leagueList = []
    data.forEach(league => {
      league.name = league.name ? league.name.trim() : `Undefined  (${league.league_id})`
      state.general.leagueList.push(league)
    })
    emitter.emit(state.events.noDataFetchError)
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.newTeamList, data => {
    if(!data || data.length === 0) {
      emitter.emit(state.events.dataFetchError, 'Error in loading data. Reload the page to try again.')
      return
    }
    state.general.teamList = []
    data.forEach(team => {
      if (data.team_id !== null)  {
        team.name = team.name ? team.name.trim() : `#${team.team_id}`
        state.general.teamList.push(team)
      }
    })
    state.general.teamList.sort((team1, team2) => {
      return team1.name.toLowerCase() < team2.name.toLowerCase() ? -1 : 1
    })
    emitter.emit(state.events.noDataFetchError)
    emitter.emit(state.events.RENDER)
  })

}