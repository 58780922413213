const html = require('choo/html')
const heroUtil = require('../utilities/heroUtil')

const getWinrate = (teamId, matches) => {
  if (matches.length === 0) return 0

  const wins = matches.reduce(((totalWins, match) => {
    const teamIsRadiant = match.radiant_team_id === teamId
    const teamWon = (teamIsRadiant && match.radiant_win) || (!teamIsRadiant && !match.radiant_win) ? 1 : 0
    return totalWins + teamWon
  }), 0)

  return Math.round(100 * wins / matches.length)
}

module.exports = function (state, emit) {
  const isTeamData = state.matchPreparation.teamId !== -1
  const teamRowStyle = isTeamData ? 'dt-row' : 'dn'
  let heroInfoBoxShown = false
  
  const showHeroStats = (event) => {
    if (event.target) {
      event.stopPropagation()
      emit(state.events.showHeroStats, event.target)
    }
  }

  const hideHeroStats = (event) => {
    if (event.target && state.matchPreparation.heroStatsBox.visible) {
      emit(state.events.hideHeroStats)
    }
  }

  const generateHeroInfoBox = (heroId) => {
    const shouldShowBox = state.matchPreparation.heroStatsBox.heroId == heroId && state.matchPreparation.heroStatsBox.visible && !heroInfoBoxShown
    if (shouldShowBox) heroInfoBoxShown = true

    if(state.matchPreparation.teamId !== -1) {
      return html`
        <div class="di relative" name=${heroId}>
          <img name=${heroId} onclick=${showHeroStats} onmouseenter=${showHeroStats} src=${heroUtil.getHeroUrl(heroId)} />
          ${shouldShowBox ?
            html`
            <div class="absolute bottom-2 left-1 bg-black ba b--washed-green washed-green dt z-1">
              <div class="dt-row">
                <img class="dtc pa2 v-mid" src=${heroUtil.getHeroUrl(heroId)} />
                <span class="dtc pa2 v-mid">${heroUtil.getHeroName(heroId)}</>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Picks</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['picksWith']}</span>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">1st phase picks</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['phaseOneWith']}</span>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Bans</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['bansWith']}</span>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Winrate with</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['picksWith'] > 0 ? Math.round(100 * state.matchPreparation.heroStats['stats'][heroId]['winsWith'] / state.matchPreparation.heroStats['stats'][heroId]['picksWith']) : 0}</span>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Picks against</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['picksAgainst']}</span>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Bans against</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['bansAgainst']}</span>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Winrate against</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['picksAgainst'] > 0 ? Math.round(100 * state.matchPreparation.heroStats['stats'][heroId]['winsAgainst'] / state.matchPreparation.heroStats['stats'][heroId]['picksAgainst']) : 0}</span>
              </div>
            </div>`
            : ''
          }
        </div>
      `
    } else {
      return html`
        <div class="di relative" name=${heroId}>
          <img name=${heroId} onclick=${showHeroStats} onmouseenter=${showHeroStats} src=${heroUtil.getHeroUrl(heroId)} />
          ${shouldShowBox ?
            html`
            <div class="absolute bottom-2 left-1 bg-black ba b--washed-green washed-green dt z-1">
              <div class="dt-row">
                <img class="dtc pa2 v-mid" src=${heroUtil.getHeroUrl(heroId)} />
                <span class="dtc pa2 v-mid">${heroUtil.getHeroName(heroId)}</>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Picks</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['picks']}</span>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Winrate</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['picks'] > 0 ? Math.round(100 * state.matchPreparation.heroStats['stats'][heroId]['wins'] / state.matchPreparation.heroStats['stats'][heroId]['picks']) : 0}</span>
              </div>
              <div class="dt-row">
                <span class="dtc pa2 v-mid">Bans</span>
                <span class="dtc pa2 v-mid">${state.matchPreparation.heroStats['stats'][heroId]['bans']}</span>
              </div>
            </div>`
            : ''
          }
        </div>
      `
    }

    
  }

  return html`
    <div onclick=${hideHeroStats}>
      <h2 class="f4">Summary</h2>

      <div class="dt f5">
        <div class="dt-row">
          <span class="dtc pa2 v-mid">Games found</span>
          <span class="dtc pa2 v-mid">${state.matchPreparation.filteredMatchData.length}</span>
        </div>
        <div class="${teamRowStyle}">
          <span class="dtc pa2 v-mid">Winrate</span>
          <span class="dtc pa2 v-mid">${isTeamData && getWinrate(state.matchPreparation.teamId, state.matchPreparation.filteredMatchData)}%</span>
        </div>
      </div>
    </div>
  `
  /*

  <div class="dt-row">
          <span class="dtc pa2 v-mid">Common picks</span>
          <div class="dtc pa2 v-mid">
            ${state.matchPreparation.heroStats.commonPicks && state.matchPreparation.heroStats.commonPicks.map(generateHeroInfoBox)}
          </div>
        </div>
        <div class="dt-row">
          <span class="dtc pa2 v-mid">Common bans</span>
          <div class="dtc pa2 v-mid">
            ${state.matchPreparation.heroStats.commonBans && state.matchPreparation.heroStats.commonBans.map(generateHeroInfoBox)}
          </div>
        </div>
        <div class="dt-row">
          <span class="dtc pa2 v-mid">Common 1st phase picks</span>
          <div class="dtc pa2 v-mid">
            ${state.matchPreparation.heroStats.commonFirstPhasePicks && state.matchPreparation.heroStats.commonFirstPhasePicks.map(generateHeroInfoBox)}
          </div>
        </div>
        <div class="${teamRowStyle}">
          <span class="dtc pa2 v-mid">Common enemy picks</span>
          <div class="dtc pa2 v-mid">
            ${state.matchPreparation.heroStats.commonEnemyPicks && state.matchPreparation.heroStats.commonEnemyPicks.map(generateHeroInfoBox)}
          </div>
        </div>
        <div class="dt-row">
          <span class="dtc pa2 v-mid">Best heroes</span>
          <div class="dtc pa2 v-mid">
            ${state.matchPreparation.heroStats.bestHeroes && state.matchPreparation.heroStats.bestHeroes.map(generateHeroInfoBox)}
          </div>
        </div>
        <div class="${teamRowStyle}">
          <span class="dtc pa2 v-mid">Best against</span>
          <div class="dtc pa2 v-mid">
            ${state.matchPreparation.heroStats.bestAgainst && state.matchPreparation.heroStats.bestAgainst.map(generateHeroInfoBox)}
          </div>
        </div>
        <div class="${teamRowStyle}">
          <span class="dtc pa2 v-mid">Worst against</span>
          <div class="dtc pa2 v-mid">
            ${state.matchPreparation.heroStats.worstAgainst && state.matchPreparation.heroStats.worstAgainst.map(generateHeroInfoBox)}
          </div>
        </div>
        <div class="${teamRowStyle}">
          <span class="dtc pa2 v-mid">Played by multiple players</span>
          <div class="dtc pa2 v-mid">
            ${state.matchPreparation.heroStats.flexPicks && state.matchPreparation.heroStats.flexPicks.map(generateHeroInfoBox)}
          </div>
        </div>
        */
}