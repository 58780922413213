module.exports = [{"hero_id":102,"name":"npc_dota_hero_abaddon","localized_name":"Abaddon","in_cm":true,"primary_attr":0,"icon_loc":0},
{"hero_id":73,"name":"npc_dota_hero_alchemist","localized_name":"Alchemist","in_cm":true,"primary_attr":0,"icon_loc":2},
{"hero_id":68,"name":"npc_dota_hero_ancient_apparition","localized_name":"Ancient Apparition","in_cm":true,"primary_attr":2,"icon_loc":3},
{"hero_id":1,"name":"npc_dota_hero_antimage","localized_name":"Anti-Mage","in_cm":true,"primary_attr":1,"icon_loc":4},
{"hero_id":113,"name":"npc_dota_hero_arc_warden","localized_name":"Arc Warden","in_cm":true,"primary_attr":1,"icon_loc":5},
{"hero_id":2,"name":"npc_dota_hero_axe","localized_name":"Axe","in_cm":true,"primary_attr":0,"icon_loc":6},
{"hero_id":3,"name":"npc_dota_hero_bane","localized_name":"Bane","in_cm":true,"primary_attr":2,"icon_loc":7},
{"hero_id":65,"name":"npc_dota_hero_batrider","localized_name":"Batrider","in_cm":true,"primary_attr":2,"icon_loc":8},
{"hero_id":38,"name":"npc_dota_hero_beastmaster","localized_name":"Beastmaster","in_cm":true,"primary_attr":0,"icon_loc":9},
{"hero_id":4,"name":"npc_dota_hero_bloodseeker","localized_name":"Bloodseeker","in_cm":true,"primary_attr":1,"icon_loc":10},
{"hero_id":62,"name":"npc_dota_hero_bounty_hunter","localized_name":"Bounty Hunter","in_cm":true,"primary_attr":1,"icon_loc":11},
{"hero_id":78,"name":"npc_dota_hero_brewmaster","localized_name":"Brewmaster","in_cm":true,"primary_attr":0,"icon_loc":12},
{"hero_id":99,"name":"npc_dota_hero_bristleback","localized_name":"Bristleback","in_cm":true,"primary_attr":0,"icon_loc":13},
{"hero_id":61,"name":"npc_dota_hero_broodmother","localized_name":"Broodmother","in_cm":true,"primary_attr":1,"icon_loc":14},
{"hero_id":96,"name":"npc_dota_hero_centaur","localized_name":"Centaur Warrunner","in_cm":true,"primary_attr":0,"icon_loc":15},
{"hero_id":81,"name":"npc_dota_hero_chaos_knight","localized_name":"Chaos Knight","in_cm":true,"primary_attr":0,"icon_loc":16},
{"hero_id":66,"name":"npc_dota_hero_chen","localized_name":"Chen","in_cm":true,"primary_attr":2,"icon_loc":17},
{"hero_id":56,"name":"npc_dota_hero_clinkz","localized_name":"Clinkz","in_cm":true,"primary_attr":1,"icon_loc":18},
{"hero_id":51,"name":"npc_dota_hero_rattletrap","localized_name":"Clockwerk","in_cm":true,"primary_attr":0,"icon_loc":81},
{"hero_id":5,"name":"npc_dota_hero_crystal_maiden","localized_name":"Crystal Maiden","in_cm":true,"primary_attr":2,"icon_loc":19},
{"hero_id":55,"name":"npc_dota_hero_dark_seer","localized_name":"Dark Seer","in_cm":true,"primary_attr":2,"icon_loc":20},
{"hero_id":119,"name":"npc_dota_hero_dark_willow","localized_name":"Dark Willow","in_cm":true,"primary_attr":2,"icon_loc":21},
{"hero_id":135,"name":"npc_dota_hero_dawnbreaker","localized_name":"Dawnbreaker","in_cm":true,"primary_attr":0,"icon_loc":22},
{"hero_id":50,"name":"npc_dota_hero_dazzle","localized_name":"Dazzle","in_cm":true,"primary_attr":2,"icon_loc":23},
{"hero_id":43,"name":"npc_dota_hero_death_prophet","localized_name":"Death Prophet","in_cm":true,"primary_attr":2,"icon_loc":24},
{"hero_id":87,"name":"npc_dota_hero_disruptor","localized_name":"Disruptor","in_cm":true,"primary_attr":2,"icon_loc":25},
{"hero_id":69,"name":"npc_dota_hero_doom_bringer","localized_name":"Doom","in_cm":true,"primary_attr":0,"icon_loc":26},
{"hero_id":49,"name":"npc_dota_hero_dragon_knight","localized_name":"Dragon Knight","in_cm":true,"primary_attr":0,"icon_loc":27},
{"hero_id":6,"name":"npc_dota_hero_drow_ranger","localized_name":"Drow Ranger","in_cm":true,"primary_attr":1,"icon_loc":28},
{"hero_id":107,"name":"npc_dota_hero_earth_spirit","localized_name":"Earth Spirit","in_cm":true,"primary_attr":0,"icon_loc":29},
{"hero_id":7,"name":"npc_dota_hero_earthshaker","localized_name":"Earthshaker","in_cm":true,"primary_attr":0,"icon_loc":30},
{"hero_id":103,"name":"npc_dota_hero_elder_titan","localized_name":"Elder Titan","in_cm":true,"primary_attr":0,"icon_loc":31},
{"hero_id":106,"name":"npc_dota_hero_ember_spirit","localized_name":"Ember Spirit","in_cm":true,"primary_attr":1,"icon_loc":32},
{"hero_id":58,"name":"npc_dota_hero_enchantress","localized_name":"Enchantress","in_cm":true,"primary_attr":2,"icon_loc":33},
{"hero_id":33,"name":"npc_dota_hero_enigma","localized_name":"Enigma","in_cm":true,"primary_attr":2,"icon_loc":34},
{"hero_id":41,"name":"npc_dota_hero_faceless_void","localized_name":"Faceless Void","in_cm":true,"primary_attr":1,"icon_loc":35},
{"hero_id":121,"name":"npc_dota_hero_grimstroke","localized_name":"Grimstroke","in_cm":true,"primary_attr":2,"icon_loc":37},
{"hero_id":72,"name":"npc_dota_hero_gyrocopter","localized_name":"Gyrocopter","in_cm":true,"primary_attr":1,"icon_loc":38},
{"hero_id":123,"name":"npc_dota_hero_hoodwink","localized_name":"Hoodwink","in_cm":true,"primary_attr":1,"icon_loc":39},
{"hero_id":59,"name":"npc_dota_hero_huskar","localized_name":"Huskar","in_cm":true,"primary_attr":0,"icon_loc":40},
{"hero_id":74,"name":"npc_dota_hero_invoker","localized_name":"Invoker","in_cm":true,"primary_attr":2,"icon_loc":41},
{"hero_id":91,"name":"npc_dota_hero_wisp","localized_name":"Io","in_cm":true,"primary_attr":0,"icon_loc":120},
{"hero_id":64,"name":"npc_dota_hero_jakiro","localized_name":"Jakiro","in_cm":true,"primary_attr":2,"icon_loc":42},
{"hero_id":8,"name":"npc_dota_hero_juggernaut","localized_name":"Juggernaut","in_cm":true,"primary_attr":1,"icon_loc":43},
{"hero_id":90,"name":"npc_dota_hero_keeper_of_the_light","localized_name":"Keeper of the Light","in_cm":true,"primary_attr":2,"icon_loc":44},
{"hero_id":23,"name":"npc_dota_hero_kunkka","localized_name":"Kunkka","in_cm":true,"primary_attr":0,"icon_loc":45},
{"hero_id":104,"name":"npc_dota_hero_legion_commander","localized_name":"Legion Commander","in_cm":true,"primary_attr":0,"icon_loc":46},
{"hero_id":52,"name":"npc_dota_hero_leshrac","localized_name":"Leshrac","in_cm":true,"primary_attr":2,"icon_loc":47},
{"hero_id":31,"name":"npc_dota_hero_lich","localized_name":"Lich","in_cm":true,"primary_attr":2,"icon_loc":48},
{"hero_id":54,"name":"npc_dota_hero_life_stealer","localized_name":"Lifestealer","in_cm":true,"primary_attr":0,"icon_loc":49},
{"hero_id":25,"name":"npc_dota_hero_lina","localized_name":"Lina","in_cm":true,"primary_attr":2,"icon_loc":50},
{"hero_id":26,"name":"npc_dota_hero_lion","localized_name":"Lion","in_cm":true,"primary_attr":2,"icon_loc":51},
{"hero_id":80,"name":"npc_dota_hero_lone_druid","localized_name":"Lone Druid","in_cm":true,"primary_attr":1,"icon_loc":52},
{"hero_id":48,"name":"npc_dota_hero_luna","localized_name":"Luna","in_cm":true,"primary_attr":1,"icon_loc":53},
{"hero_id":77,"name":"npc_dota_hero_lycan","localized_name":"Lycan","in_cm":true,"primary_attr":0,"icon_loc":54},
{"hero_id":97,"name":"npc_dota_hero_magnataur","localized_name":"Magnus","in_cm":true,"primary_attr":0,"icon_loc":55},
{"hero_id":136,"name":"npc_dota_hero_marci","localized_name":"Marci","in_cm":true,"primary_attr":0,"icon_loc":56},
{"hero_id":129,"name":"npc_dota_hero_mars","localized_name":"Mars","in_cm":true,"primary_attr":0,"icon_loc":57},
{"hero_id":94,"name":"npc_dota_hero_medusa","localized_name":"Medusa","in_cm":true,"primary_attr":1,"icon_loc":58},
{"hero_id":82,"name":"npc_dota_hero_meepo","localized_name":"Meepo","in_cm":true,"primary_attr":1,"icon_loc":59},
{"hero_id":9,"name":"npc_dota_hero_mirana","localized_name":"Mirana","in_cm":true,"primary_attr":1,"icon_loc":60},
{"hero_id":114,"name":"npc_dota_hero_monkey_king","localized_name":"Monkey King","in_cm":true,"primary_attr":1,"icon_loc":61},
{"hero_id":10,"name":"npc_dota_hero_morphling","localized_name":"Morphling","in_cm":true,"primary_attr":1,"icon_loc":62},
{"hero_id":89,"name":"npc_dota_hero_naga_siren","localized_name":"Naga Siren","in_cm":true,"primary_attr":1,"icon_loc":63},
{"hero_id":53,"name":"npc_dota_hero_furion","localized_name":"Nature's Prophet","in_cm":true,"primary_attr":2,"icon_loc":36},
{"hero_id":36,"name":"npc_dota_hero_necrolyte","localized_name":"Necrophos","in_cm":true,"primary_attr":2,"icon_loc":64},
{"hero_id":60,"name":"npc_dota_hero_night_stalker","localized_name":"Night Stalker","in_cm":true,"primary_attr":0,"icon_loc":66},
{"hero_id":88,"name":"npc_dota_hero_nyx_assassin","localized_name":"Nyx Assassin","in_cm":true,"primary_attr":1,"icon_loc":67},
{"hero_id":84,"name":"npc_dota_hero_ogre_magi","localized_name":"Ogre Magi","in_cm":true,"primary_attr":2,"icon_loc":69},
{"hero_id":57,"name":"npc_dota_hero_omniknight","localized_name":"Omniknight","in_cm":true,"primary_attr":0,"icon_loc":70},
{"hero_id":111,"name":"npc_dota_hero_oracle","localized_name":"Oracle","in_cm":true,"primary_attr":2,"icon_loc":71},
{"hero_id":76,"name":"npc_dota_hero_obsidian_destroyer","localized_name":"Outworld Devourer","in_cm":true,"primary_attr":2,"icon_loc":68},
{"hero_id":120,"name":"npc_dota_hero_pangolier","localized_name":"Pangolier","in_cm":true,"primary_attr":1,"icon_loc":72},
{"hero_id":44,"name":"npc_dota_hero_phantom_assassin","localized_name":"Phantom Assassin","in_cm":true,"primary_attr":1,"icon_loc":73},
{"hero_id":12,"name":"npc_dota_hero_phantom_lancer","localized_name":"Phantom Lancer","in_cm":true,"primary_attr":1,"icon_loc":74},
{"hero_id":110,"name":"npc_dota_hero_phoenix","localized_name":"Phoenix","in_cm":true,"primary_attr":0,"icon_loc":75},
{"hero_id":137,"name":"npc_dota_hero_primal_beast","localized_name":"Primal Beast","in_cm":true,"primary_attr":0,"icon_loc":76},
{"hero_id":13,"name":"npc_dota_hero_puck","localized_name":"Puck","in_cm":true,"primary_attr":2,"icon_loc":77},
{"hero_id":14,"name":"npc_dota_hero_pudge","localized_name":"Pudge","in_cm":true,"primary_attr":0,"icon_loc":78},
{"hero_id":45,"name":"npc_dota_hero_pugna","localized_name":"Pugna","in_cm":true,"primary_attr":2,"icon_loc":79},
{"hero_id":39,"name":"npc_dota_hero_queenofpain","localized_name":"Queen of Pain","in_cm":true,"primary_attr":2,"icon_loc":80},
{"hero_id":15,"name":"npc_dota_hero_razor","localized_name":"Razor","in_cm":true,"primary_attr":1,"icon_loc":82},
{"hero_id":32,"name":"npc_dota_hero_riki","localized_name":"Riki","in_cm":true,"primary_attr":1,"icon_loc":83},
{"hero_id":86,"name":"npc_dota_hero_rubick","localized_name":"Rubick","in_cm":true,"primary_attr":2,"icon_loc":84},
{"hero_id":16,"name":"npc_dota_hero_sand_king","localized_name":"Sand King","in_cm":true,"primary_attr":0,"icon_loc":85},
{"hero_id":79,"name":"npc_dota_hero_shadow_demon","localized_name":"Shadow Demon","in_cm":true,"primary_attr":2,"icon_loc":86},
{"hero_id":11,"name":"npc_dota_hero_nevermore","localized_name":"Shadow Fiend","in_cm":true,"primary_attr":1,"icon_loc":65},
{"hero_id":27,"name":"npc_dota_hero_shadow_shaman","localized_name":"Shadow Shaman","in_cm":true,"primary_attr":2,"icon_loc":87},
{"hero_id":75,"name":"npc_dota_hero_silencer","localized_name":"Silencer","in_cm":true,"primary_attr":2,"icon_loc":89},
{"hero_id":101,"name":"npc_dota_hero_skywrath_mage","localized_name":"Skywrath Mage","in_cm":true,"primary_attr":2,"icon_loc":91},
{"hero_id":28,"name":"npc_dota_hero_slardar","localized_name":"Slardar","in_cm":true,"primary_attr":0,"icon_loc":92},
{"hero_id":93,"name":"npc_dota_hero_slark","localized_name":"Slark","in_cm":true,"primary_attr":1,"icon_loc":93},
{"hero_id":128,"name":"npc_dota_hero_snapfire","localized_name":"Snapfire","in_cm":true,"primary_attr":0,"icon_loc":94},
{"hero_id":35,"name":"npc_dota_hero_sniper","localized_name":"Sniper","in_cm":true,"primary_attr":1,"icon_loc":95},
{"hero_id":67,"name":"npc_dota_hero_spectre","localized_name":"Spectre","in_cm":true,"primary_attr":1,"icon_loc":96},
{"hero_id":71,"name":"npc_dota_hero_spirit_breaker","localized_name":"Spirit Breaker","in_cm":true,"primary_attr":0,"icon_loc":97},
{"hero_id":17,"name":"npc_dota_hero_storm_spirit","localized_name":"Storm Spirit","in_cm":true,"primary_attr":2,"icon_loc":98},
{"hero_id":18,"name":"npc_dota_hero_sven","localized_name":"Sven","in_cm":true,"primary_attr":0,"icon_loc":99},
{"hero_id":105,"name":"npc_dota_hero_techies","localized_name":"Techies","in_cm":true,"primary_attr":2,"icon_loc":100},
{"hero_id":46,"name":"npc_dota_hero_templar_assassin","localized_name":"Templar Assassin","in_cm":true,"primary_attr":1,"icon_loc":101},
{"hero_id":109,"name":"npc_dota_hero_terrorblade","localized_name":"Terrorblade","in_cm":true,"primary_attr":1,"icon_loc":102},
{"hero_id":29,"name":"npc_dota_hero_tidehunter","localized_name":"Tidehunter","in_cm":true,"primary_attr":0,"icon_loc":103},
{"hero_id":98,"name":"npc_dota_hero_shredder","localized_name":"Timbersaw","in_cm":true,"primary_attr":0,"icon_loc":88},
{"hero_id":34,"name":"npc_dota_hero_tinker","localized_name":"Tinker","in_cm":true,"primary_attr":2,"icon_loc":104},
{"hero_id":19,"name":"npc_dota_hero_tiny","localized_name":"Tiny","in_cm":true,"primary_attr":0,"icon_loc":105},
{"hero_id":83,"name":"npc_dota_hero_treant","localized_name":"Treant Protector","in_cm":true,"primary_attr":0,"icon_loc":106},
{"hero_id":95,"name":"npc_dota_hero_troll_warlord","localized_name":"Troll Warlord","in_cm":true,"primary_attr":1,"icon_loc":107},
{"hero_id":100,"name":"npc_dota_hero_tusk","localized_name":"Tusk","in_cm":true,"primary_attr":0,"icon_loc":108},
{"hero_id":108,"name":"npc_dota_hero_abyssal_underlord","localized_name":"Underlord","in_cm":true,"primary_attr":0,"icon_loc":1},
{"hero_id":85,"name":"npc_dota_hero_undying","localized_name":"Undying","in_cm":true,"primary_attr":0,"icon_loc":109},
{"hero_id":70,"name":"npc_dota_hero_ursa","localized_name":"Ursa","in_cm":true,"primary_attr":1,"icon_loc":110},
{"hero_id":20,"name":"npc_dota_hero_vengefulspirit","localized_name":"Vengeful Spirit","in_cm":true,"primary_attr":1,"icon_loc":111},
{"hero_id":40,"name":"npc_dota_hero_venomancer","localized_name":"Venomancer","in_cm":true,"primary_attr":1,"icon_loc":112},
{"hero_id":47,"name":"npc_dota_hero_viper","localized_name":"Viper","in_cm":true,"primary_attr":1,"icon_loc":113},
{"hero_id":92,"name":"npc_dota_hero_visage","localized_name":"Visage","in_cm":true,"primary_attr":2,"icon_loc":114},
{"hero_id":126,"name":"npc_dota_hero_void_spirit","localized_name":"Void Spirit","in_cm":true,"primary_attr":2,"icon_loc":115},
{"hero_id":37,"name":"npc_dota_hero_warlock","localized_name":"Warlock","in_cm":true,"primary_attr":2,"icon_loc":116},
{"hero_id":63,"name":"npc_dota_hero_weaver","localized_name":"Weaver","in_cm":true,"primary_attr":1,"icon_loc":117},
{"hero_id":21,"name":"npc_dota_hero_windrunner","localized_name":"Windranger","in_cm":true,"primary_attr":2,"icon_loc":118},
{"hero_id":112,"name":"npc_dota_hero_winter_wyvern","localized_name":"Winter Wyvern","in_cm":true,"primary_attr":2,"icon_loc":119},
{"hero_id":30,"name":"npc_dota_hero_witch_doctor","localized_name":"Witch Doctor","in_cm":true,"primary_attr":2,"icon_loc":121},
{"hero_id":42,"name":"npc_dota_hero_skeleton_king","localized_name":"Wraith King","in_cm":true,"primary_attr":0,"icon_loc":90},
{"hero_id":22,"name":"npc_dota_hero_zuus","localized_name":"Zeus","in_cm":true,"primary_attr":2,"icon_loc":122}]
