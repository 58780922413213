const constraintTypes = require('../utilities/constraints')
const matchFiltering = require('../utilities/matchFiltering')
const getFilteredTeamMatches = matchFiltering.getFilteredTeamMatches
const getFilteredLeagueMatches = matchFiltering.getFilteredLeagueMatches

module.exports = (state, emitter) => {
  state.matchPreparation = {
    teamName: '',
    teamId: -1,
    leagueId: -1,
    matchData: [],
    filteredMatchData: [],
    heroStats: {},
    opponents: [],
    participatingTeams: [],
    leagues: [],
    patches: {},
    constraints: [{active: false}],
    matchListPage: 0,
    errorText: '',
    heroStatsBox: {
      x: 0,
      y: 0,
      heroId: -1,
      visible: false
    }
  }
  state.events = Object.assign({
    prepareForTeam: 'prepareForTeam',
    prepareForEvent: 'prepareForEvent',
    newTeamMatchData: 'newTeamMatchData',
    newLeagueMatchData: 'newLeagueMatchData',
    addConstraint: 'addConstraint',
    changeConstraint: 'changeConstraint',
    removeConstraint: 'removeConstraint',
    newTeamHeroData: 'newTeamHeroData',
    changePage: 'changePage',
    dataFetchError: 'dataFetchError',
    noDataFetchError: 'noDataFetchError',
    showHeroStats: 'showHeroStats',
    hideHeroStats: 'hideHeroStats'

  }, state.events)

  const nextPageFromState = _ => {
    emitter.emit(state.events.PUSHSTATE, `/matches?${stateToQueryString()}`)
  }

  const stateToQueryString = () => {
    let queryString = ''
    const constraintNames = []

    if (state.matchPreparation.teamId !== -1) {
      queryString += `team=${state.matchPreparation.teamId}`
      for ([constraintName, constraintId] of Object.entries(constraintTypes.team)) {
        constraintNames[constraintId] = constraintName
      }
    } else if (state.matchPreparation.leagueId !== -1) {
      queryString += `league=${state.matchPreparation.leagueId}`
      for ([constraintName, constraintId] of Object.entries(constraintTypes.league)) {
        constraintNames[constraintId] = constraintName
      }
    }

    state.matchPreparation.constraints.forEach(constraint => {
      if (constraint.active) {
        queryString += `&${constraintNames[constraint.constraintType]}=`
        let firstEntry = true
        for ([dataItem, dataValue] of Object.entries(constraint.constraintData)) {
          if (firstEntry) {
            firstEntry = false
          } else {
            queryString += '+'
          }
          queryString += `${dataItem}-${dataValue}`
        }
      }
    })
    queryString += `&page=${state.matchPreparation.matchListPage}`
    return queryString
  }

  const queryStringToState = () => {
    const constraints = []
    let types
    const team = parseInt(state.query.team)
    if (team) {
      state.matchPreparation.teamId = team
      types = constraintTypes.team
    } else {
      state.matchPreparation.teamId = -1
    }
    
    const league = parseInt(state.query.league)
    if (league) {
      state.matchPreparation.leagueId = league
      types = constraintTypes.league
    } else {
      state.matchPreparation.leagueId = -1
    }

    if (!team && !league) {
      return
    }

    const page = parseInt(state.query.page)
    state.matchPreparation.matchListPage = page ? page : 0

    for ([constraintName, data] of Object.entries(state.query)) {
      if(constraintName === 'team' || constraintName === 'league' || constraintName === 'page') continue

      if(Array.isArray(data)) {
        data.forEach(item => {
          const constraint = {active: true}
          constraint.constraintType = types[constraintName]
          constraint.constraintData = {}
          const split = item.split('+')
          split.forEach(dataItem => {
            const hyphenIndex = dataItem.indexOf('-')
            const itemName = dataItem.substring(0, hyphenIndex)
            const itemValue = dataItem.substring(hyphenIndex + 1, dataItem.length)
            if (itemName !== 'patch') {
              constraint.constraintData[itemName] = parseInt(itemValue)
            } else {
              constraint.constraintData[itemName] = itemValue
            }
          })
          constraints.push(constraint)
        })
      } else {
        const constraint = {active: true}
        constraint.constraintType = types[constraintName]
        constraint.constraintData = {}
        const split = data.split('+')
        split.forEach(dataItem => {
          const hyphenIndex = dataItem.indexOf('-')
          const itemName = dataItem.substring(0, hyphenIndex)
          const itemValue = dataItem.substring(hyphenIndex + 1, dataItem.length)
          if (itemName !== 'patch') {
            constraint.constraintData[itemName] = parseInt(itemValue)
          } else {
            constraint.constraintData[itemName] = itemValue
          }
        })
        constraints.push(constraint)
      }
    }
    constraints.push([{active: false}])
    state.matchPreparation.constraints = constraints
    
  }

  emitter.on(state.events.DOMCONTENTLOADED, _ => {
    if (state.route === 'matches') {
      queryStringToState()
      if (state.query.team) {
        state.dataFetch.getMatchesByTeam(state.matchPreparation.teamId, state, emitter)
        const teamObject = state.general.teamList.find(team => team.team_id == state.matchPreparation.teamId)
        if(teamObject) {
          state.matchPreparation.teamName = teamObject.name
        }
      } else if (state.query.league) {
        state.dataFetch.getMatchesByLeague(state.matchPreparation.leagueId, state, emitter)
      }
    }
  })

  emitter.on(state.events.POPSTATE, _ => {
    if (state.route === 'matches') {
      const previousTeamId = state.matchPreparation.teamId
      const previousLeagueId = state.matchPreparation.leagueId
      queryStringToState()
      if (state.matchPreparation.teamId !== -1) {
        if (state.matchPreparation.teamId !== previousTeamId) {
          state.dataFetch.getMatchesByTeam(state.matchPreparation.teamId, state, emitter)
          const teamObject = state.general.teamList.find(team => team.team_id == state.matchPreparation.teamId)
          if(teamObject) {
            state.matchPreparation.teamName = teamObject.name
          }
        } else {
          state.matchPreparation.filteredMatchData = getFilteredTeamMatches(state.matchPreparation.teamId, state.matchPreparation.matchData, state.matchPreparation.constraints)
        }
        

      } else if (state.matchPreparation.leagueId !== -1) {
        if (state.matchPreparation.leagueId !== previousLeagueId) {
          state.dataFetch.getMatchesByLeague(state.matchPreparation.leagueId, state, emitter)
        } else {
          state.matchPreparation.filteredMatchData = getFilteredLeagueMatches(state.matchPreparation.matchData, state.matchPreparation.constraints)
        }
      }
      emitter.emit(state.events.RENDER)
    }
  })

  emitter.on(state.events.prepareForTeam, (teamId) => {
    if(teamId === state.matchPreparation.teamId) return
    if(state.matchPreparation.leagueId !== -1) {
      state.matchPreparation.leagueId = -1
      state.matchPreparation.constraints = [{active: true, constraintType: constraintTypes.team.matchTime, constraintData: {time: 3}, permanent: true}, {active: false}]
    }

    state.matchPreparation.matchData = []
    state.matchPreparation.filteredMatchData = []
    state.matchPreparation.matchListPage = 0
    if(teamId === -1) {
      state.matchPreparation.teamName = ''
      state.matchPreparation.teamId = -1
      state.matchPreparation.constraints = [{active: false}]
    } else {
      state.dataFetch.getMatchesByTeam(teamId, state, emitter)
      state.matchPreparation.teamId = teamId
      const teamObject = state.general.teamList.find(team => team.team_id == teamId)
      if(teamObject) {
        state.matchPreparation.teamName = teamObject.name
      }
    }
    nextPageFromState()
  })

  emitter.on(state.events.newTeamMatchData, (data) => {
    if(state.matchPreparation.leagueId !== -1) {
      return
    }
    state.matchPreparation.matchData = data

    state.matchPreparation.patches = {}
    state.matchPreparation.opponents = []
    state.matchPreparation.participatingTeams = []

    state.matchPreparation.matchData.forEach(match => {
      match.picksandbans.sort((pick1, pick2) => pick1.ord - pick2.ord)
      const radiant = match.radiant_team_id === state.matchPreparation.teamId
      const enemyTeamId = radiant ? match.dire_team_id : match.radiant_team_id
      let enemyTeamName = radiant ? match.dire_team_name : match.radiant_team_name
      if(!enemyTeamName) {
        if (enemyTeamId !== null) {
          enemyTeamName = `#${match.radiant_team_id}`
        } else {
          enemyTeamName = radiant ? 'Unnamed Dire Team' : 'Unnamed Radiant Team'
        }

        if (radiant) {
          match.dire_team_name = enemyTeamName
        } else {
          match.radiant_team_name = enemyTeamName
        }
      }
      const enemy = {teamId: enemyTeamId, name: enemyTeamName.trim()}
      if(enemyTeamId !== null && state.matchPreparation.opponents.findIndex(team => team.teamId === enemyTeamId) === -1) {
        state.matchPreparation.opponents.push(enemy)
      }
      const currentLeague = {leagueId: match.league_id, name: match.league_name}
      if(state.matchPreparation.leagues.findIndex(league => league.leagueId === currentLeague.leagueId) === -1) {
        state.matchPreparation.leagues.push(currentLeague)
      }
      state.matchPreparation.patches[match.patch] = true
    })

    if (!state.matchPreparation.teamName) {
      state.matchPreparation.teamName = data[0].radiant_team_id === state.matchPreparation.teamId ? data[0].radiant_team_name : data[0].dire_team_name
    }

    state.matchPreparation.filteredMatchData = getFilteredTeamMatches(state.matchPreparation.teamId, state.matchPreparation.matchData, state.matchPreparation.constraints)
    //state.matchPreparation.heroStats = matchFiltering.createHeroStatsForTeam(state.matchPreparation.filteredMatchData, state.matchPreparation.teamId)
    state.matchPreparation.opponents.sort((op1, op2) => {
      return op1.name.toLowerCase() < op2.name.toLowerCase() ? -1 : 1 
    })
    emitter.emit(state.events.noDataFetchError)
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.prepareForEvent, leagueId => {
    if(leagueId === state.matchPreparation.leagueId) return
    if(state.matchPreparation.teamId !== -1) {
      state.matchPreparation.teamId = -1
      state.matchPreparation.constraints = [{active: false}]
    }

    state.matchPreparation.matchData = []
    state.matchPreparation.filteredMatchData = []
    state.matchPreparation.matchListPage = 0
    if(leagueId === -1) {
      state.matchPreparation.leagueId = -1
      state.matchPreparation.constraints = [{active: false}]
    } else {
      state.dataFetch.getMatchesByLeague(leagueId, state, emitter)
      state.matchPreparation.leagueId = leagueId
    }
    nextPageFromState()
  })

  emitter.on(state.events.newLeagueMatchData, data => {
    if(state.matchPreparation.teamId !== -1) {
      return
    }

    state.matchPreparation.matchData = data
    state.matchPreparation.patches = {}
    state.matchPreparation.participatingTeams = []

    state.matchPreparation.matchData.forEach(match => {
      match.picksandbans.sort((pick1, pick2) => pick1.ord - pick2.ord)
      state.matchPreparation.patches[match.patch] = true

      let radiantTeamName = match.radiant_team_name
      let direTeamName = match.dire_team_name
      if(!radiantTeamName) {
        if (match.radiant_team_id !== null) {
          radiantTeamName = `#${match.radiant_team_id}`
        } else {
          radiantTeamName = 'Unnamed Radiant Team'
        }

        match.radiant_team_name = radiantTeamName
      }
      if(!direTeamName) {
        if (match.dire_team_id !== null) {
          direTeamName = `#${match.dire_team_id}`
        } else {
          direTeamName = 'Unnamed Dire Team'
        }

        match.dire_team_name = direTeamName
      }
      const team1 = {teamId: match.radiant_team_id, name: radiantTeamName}
      const team2 = {teamId: match.dire_team_id, name: direTeamName}
      
      if(team1.teamId !== null && state.matchPreparation.participatingTeams.findIndex(team => team.teamId === team1.teamId) === -1) {
        state.matchPreparation.participatingTeams.push(team1)
      }
      if(team2.teamId !== null && state.matchPreparation.participatingTeams.findIndex(team => team.teamId === team2.teamId) === -1) {
        state.matchPreparation.participatingTeams.push(team2)
      }
    })
    state.matchPreparation.participatingTeams.sort((op1, op2) => {
      return op1.name.toLowerCase() < op2.name.toLowerCase() ? -1 : 1 
    })
    state.matchPreparation.filteredMatchData = getFilteredLeagueMatches(state.matchPreparation.matchData, state.matchPreparation.constraints)

    //state.matchPreparation.heroStats = matchFiltering.createHeroStatsForLeague(state.matchPreparation.filteredMatchData)
    emitter.emit(state.events.noDataFetchError)
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.changePage, change => {
    state.matchPreparation.matchListPage += change
    nextPageFromState()
  })

  emitter.on(state.events.addConstraint, constraintType => {
    const numOfConstraints = state.matchPreparation.constraints.length
    state.matchPreparation.constraints[numOfConstraints-1].active = true
    state.matchPreparation.constraints[numOfConstraints-1].constraintType = constraintType
    if(state.matchPreparation.teamId !== -1) {
      switch(constraintType) {
        case constraintTypes.team.heroInMatch:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {heroId: -1, pickPhase: -1, pickType: 0}
          break
        case constraintTypes.team.atEvent:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {leagueId: -1}
          break
        case constraintTypes.team.againstTeam:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {teamId: -1}
          break
        case constraintTypes.team.onPatch:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {patch: 'any'}
          break
        case constraintTypes.team.onSide:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {side: -1}
          break
        case constraintTypes.team.result:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {result: -1}
          break
        case constraintTypes.team.pickOrder:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {order: -1}
          break
        case constraintTypes.team.matchTime:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {time: -1}
          break
      }
    } else if(state.matchPreparation.leagueId !== -1) {
      switch(constraintType) {
        case constraintTypes.league.withHeroInMatch:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {heroId: -1, pickPhase: -1, pickType: 0}
          break
        case constraintTypes.league.includesTeam:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {teamId: -1}
          break
        case constraintTypes.league.result:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {result: -1}
          break
        case constraintTypes.league.onPatch:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {patch: 'any'}
          break
        case constraintTypes.league.pickOrder:
          state.matchPreparation.constraints[numOfConstraints-1].constraintData = {order: -1}
          break
      }
    }
    
    state.matchPreparation.constraints.push({active: false})

    nextPageFromState()
  })

  emitter.on(state.events.changeConstraint, args => {
    state.matchPreparation.matchListPage = 0
    const index = args[1]
    const changes = args[0]
    
    if(changes.constraintType) {
      state.matchPreparation.constraints[index].constraintType = changes.constraintType
    }
    if(changes.constraintData) {
      Object.assign(state.matchPreparation.constraints[index].constraintData, changes.constraintData)
    }
    if(state.matchPreparation.teamId !== -1) {
      state.matchPreparation.filteredMatchData = getFilteredTeamMatches(state.matchPreparation.teamId, state.matchPreparation.matchData, state.matchPreparation.constraints)
      //state.matchPreparation.heroStats = matchFiltering.createHeroStatsForTeam(state.matchPreparation.filteredMatchData, state.matchPreparation.teamId)
    } else if(state.matchPreparation.leagueId !== -1) {
      state.matchPreparation.filteredMatchData = getFilteredLeagueMatches(state.matchPreparation.matchData, state.matchPreparation.constraints)
      //state.matchPreparation.heroStats = matchFiltering.createHeroStatsForLeague(state.matchPreparation.filteredMatchData)
    }
    nextPageFromState()
  })
  
  emitter.on(state.events.removeConstraint, (index) => {
    state.matchPreparation.matchListPage = 0
    state.matchPreparation.constraints.splice(index, 1)
    if(state.matchPreparation.teamId !== -1) {
      state.matchPreparation.filteredMatchData = getFilteredTeamMatches(state.matchPreparation.teamId, state.matchPreparation.matchData, state.matchPreparation.constraints)
      //state.matchPreparation.heroStats = matchFiltering.createHeroStatsForTeam(state.matchPreparation.filteredMatchData, state.matchPreparation.teamId)
    } else if(state.matchPreparation.leagueId !== -1) {
      state.matchPreparation.filteredMatchData = getFilteredLeagueMatches(state.matchPreparation.matchData, state.matchPreparation.constraints)
      //state.matchPreparation.heroStats = matchFiltering.createHeroStatsForLeague(state.matchPreparation.filteredMatchData)
    }
    nextPageFromState()
  })

  emitter.on(state.events.dataFetchError, text => {
    state.matchPreparation.errorText = text
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.noDataFetchError, () => {
    state.matchPreparation.errorText = ''
  })

  emitter.on(state.events.showHeroStats, eventTarget => {
    const heroId = parseInt(eventTarget.name)
    const x = 0
    const y = 0
    state.matchPreparation.heroStatsBox.heroId = heroId
    state.matchPreparation.heroStatsBox.x = x
    state.matchPreparation.heroStatsBox.y = y
    state.matchPreparation.heroStatsBox.visible = true
    emitter.emit(state.events.RENDER)
  })

  emitter.on(state.events.hideHeroStats, eventTarget => {
    state.matchPreparation.heroStatsBox.visible = false
    emitter.emit(state.events.RENDER)
  })
}