const html = require('choo/html')

const DrawableMap = require('../components/drawableMap')
const canvasNode = new DrawableMap()

const Header = require('../components/header')
const Footer = require('../components/footer')

const ICONS_IN_ROW = 16

const addHeroIcon = event => {
  const heroSize = event.target.width / ICONS_IN_ROW
  const xIndex = Math.floor(event.offsetX / heroSize)
  const yIndex = Math.floor(event.offsetY / heroSize)
  canvasNode.addHeroIcon(xIndex, yIndex)
}

const removeDrawings = event => {
  canvasNode.removeDrawings()
}

const removeIcons = event => {
  canvasNode.removeIcons()
}

const saveImageNode = html`<a class="dn"></a>`

const saveImage = event => {
  saveImageNode.href = canvasNode.getDrawing()
  saveImageNode.download = 'mapdrawing.png'
  saveImageNode.click()
}

const title = 'Map Drawing'

module.exports = (state, emit) => {
  if (state.title !== title) emit(state.events.DOMTITLECHANGE, title)
  const toggleBrushColor = event => {
    canvasNode.toggleBrushColor()
    emit(state.events.RENDER)
  }

  const brushColor = canvasNode.isUsingRedBrush ? 'red-brush-button' : 'green-brush-button'
  return html`
    <body class="bg-near-black pv1 ph1 ph2-ns ph3-m ph3-l cf">
      ${state.cache(Header, 'header').render()}
      <div class="fl w-100 pa4-l pa3-m pa1-ns">
        <p class="f6">
          Draw on the minimap. Click the icon you want to add. Drag it around. Drag it outside the image to remove.
        </p>
        <div class="fl w-60 w-50-m w-50-l">
          ${canvasNode.render()}
        </div>
        <div class="fl w-40 w-50-m w-50-l pa2 pl3">
          <img src="../assets/images/minimap_icons.png" onclick=${addHeroIcon} />
          <div>
            <button class="f6 ph1 ph1-ns ph3-m ph3-l pv1 pv1-ns pv2-m pv2-l mb1 mr1 mrdib black ${brushColor}" onclick=${toggleBrushColor}>Toggle brush</button>
            <button class="f6 ph1 ph1-ns ph3-m ph3-l pv1 pv1-ns pv2-m pv2-l mb1 mr1 dib black bg-white" onclick=${removeDrawings}>Remove drawings</button>
            <button class="f6 ph1 ph1-ns ph3-m ph3-l pv1 pv1-ns pv2-m pv2-l mb1 mr1 black bg-white" onclick=${removeIcons}>Remove icons</button>
            <div>
              <button class="f6 ph1 ph1-ns ph3-m ph3-l pv1 pv1-ns pv2-m pv2-l mb1 dib black bg-white" onclick=${saveImage}>Download image</button>
              ${saveImageNode}
            </div>
          </div>
        </div>
      </div>
      ${state.cache(Footer, 'footer').render()}
    </body>
  `
}