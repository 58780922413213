const css = require('sheetify')
const choo = require('choo')

css('tachyons')
css('./styles/styles.css')
css('./styles/fontello.css')

const app = choo({'cache': 200})
if (process.env.NODE_ENV !== 'production') {
  app.use(require('choo-devtools')())
} else {
  app.use(require('choo-service-worker')())
}

app.use(require('./stores/general'))
app.use(require('./stores/matchPreparation'))
app.use(require('./stores/draftPractice'))
app.use(require('./stores/match'))

app.route('*', require('./views/pageNotFound'))
app.route('/', require('./views/homePage'))
app.route('/matches', require('./views/matchPage'))
app.route('/draft', require('./views/draftPractice'))
app.route('/mapdrawing', require('./views/mapDrawing'))
app.route('/match/:matchId', require('./views/matchDetail'))

module.exports = app.mount('body')
