const Component = require('choo/component')
const html = require('choo/html')
const HeroRowImage = require('./heroRowImage')

class HeroRow extends Component {
  constructor(id, state, emit) {
    super(id)
    this.state = state
    this.emit = emit
  }

  createElement(title, heroes) {
    return html`
      <div>
        <h3 class="f5">${title}</h3>
        <div id="hero-row-${this.id}" name="row-${this.id}" class="mb2">
          ${heroes.map(hero => this.state.cache(HeroRowImage, `hero-image-${hero.hero_id}`).render(hero))}
        </div>
      </div>
    `
  }

  update(title, heroes) {
    heroes.forEach(hero => {
      this.state.cache(HeroRowImage, `hero-image-${hero.hero_id}`).render(hero)
    })
    return false
  }
}

module.exports = HeroRow