const teamConstraints = {
  againstTeam: 0,
  withHero: 1,
  againstHero: 2,
  heroInMatch: 3,
  atEvent: 4,
  onPatch: 5,
  duration: 6,
  onSide: 7,
  result: 8,
  pickOrder: 9,
  matchTime: 10
}

const leagueConstraints = {
  includesTeam: 0,
  withHeroRadiant: 1,
  withHeroDire: 2,
  withHeroInMatch: 3,
  onPatch: 5,
  result: 6,
  pickOrder: 7
}

module.exports = {
  team: teamConstraints,
  league: leagueConstraints
}