const html = require('choo/html')
const constraintTypes = require('../utilities/constraints')
const heroData = require('../utilities/heroData')

const teamConstraintOptions = [
  {value: constraintTypes.team.result, title: 'Match result'},
  {value: constraintTypes.team.onSide, title: 'Side'},
  {value: constraintTypes.team.pickOrder, title: 'Pick order'},
  {value: constraintTypes.team.heroInMatch, title: 'Hero'},
  {value: constraintTypes.team.againstTeam, title: 'Opponent'},
  {value: constraintTypes.team.atEvent, title: 'Event'},
  {value: constraintTypes.team.onPatch, title: 'Patch'}
]
const leagueConstraintOptions = [
  {value: constraintTypes.league.result, title: 'Match result'},
  {value: constraintTypes.league.pickOrder, title: 'Pick order'},
  {value: constraintTypes.league.withHeroInMatch, title: 'Hero'},
  {value: constraintTypes.league.includesTeam, title: 'Team'},
  {value: constraintTypes.league.onPatch, title: 'Patch'},
]

module.exports = function (state, emit) {
  const getConstraintAdder = _ => {
    const options = state.matchPreparation.teamId !== -1 ? teamConstraintOptions : leagueConstraintOptions
    return html`
      <select class="constraint-btn add-constraint-box" onchange=${addConstraint}>
        <option selected>Add filter</option>
        ${options.map(option => html`<option value=${option.value}>${option.title}</option>`)}
      </select>
    `
  }

  const addConstraint = event => {
    const constraintType = parseInt(event.target.value)
    emit(state.events.addConstraint, constraintType)
  }

  const getConstraintTeamSelector = (constraint, index) => {
    const optionList = state.matchPreparation.teamId !== -1 ? state.matchPreparation.opponents : state.matchPreparation.participatingTeams
    const defaultOptionText = state.matchPreparation.teamId !== -1 ? 'Any opponent' : 'Any team'
    return html`
      <div>
        <select class="constraint-select" name=${index} onchange=${constraintTeamChanged}>
          <option value="-1">${defaultOptionText}</option>
          ${optionList.map(team => 
            html`<option value=${team.teamId} selected=${constraint.constraintData.teamId == team.teamId}>${team.name}</option>`
          )}
        </select>
      </div>
    `
  }

  const getConstraintEventSelector = (constraint, index) => {
    return html`
      <div>
        <select class="constraint-select" name=${index} onchange=${constraintEventChanged}>
          <option value="-1">Any event</option>
          ${state.matchPreparation.leagues.map(league => {
            return html`<option value=${league.leagueId} selected=${constraint.constraintData.leagueId == league.leagueId}>${league.name}</option>`
          })}
        </select>
      </div>
    `
  }

  const constraintEventChanged = event => {
    if(event.target.value) {
      const newEvent = parseInt(event.target.value)
      const constraintIndex = parseInt(event.target.name)
      const constraint = { constraintData: { leagueId: newEvent } }
      emit(state.events.changeConstraint, [constraint, constraintIndex])
    }
  }

  const getConstraintPatchSelector = (constraint, index) => {
    return html`
      <div>
        <select class="constraint-select" name=${index} onchange=${constraintPatchChanged}>
          <option value="any">Any patch</option>
          ${Object.keys(state.matchPreparation.patches).map((patch, ind) =>
            html`<option value=${patch} selected=${constraint.constraintData.patch == patch}>${patch}</option>`
          )}
        </select>
      </div>
    `
  }

  const constraintPatchChanged = event => {
    if(event.target.value) {
      const newPatch = event.target.value
      const constraintIndex = parseInt(event.target.name)
      const constraint = { constraintData: { patch: newPatch } }
      emit(state.events.changeConstraint, [constraint, constraintIndex])
    }
  }

  const getConstraintSideSelector = (constraint, index) => {
    return html`
    <div>
      <select class="constraint-select" name=${index} onchange=${constraintSideChanged}>
        <option value="-1">Any side</option>
        <option value="0" selected=${constraint.constraintData.side === 0}>${state.matchPreparation.teamName} is radiant</option>
        <option value="1" selected=${constraint.constraintData.side === 1}>${state.matchPreparation.teamName} is dire</option>
      </select>
    </div>
    `
  }

  const constraintSideChanged = event => {
    if(event.target.value) {
      const newSide = parseInt(event.target.value)
      const constraintIndex = parseInt(event.target.name)
      const constraint = { constraintData: { side: newSide } }
      emit(state.events.changeConstraint, [constraint, constraintIndex])
    }
  }

  const getConstraintPickOrderSelector = (constraint, index) => {
    const option1 = state.matchPreparation.teamId !== -1 ? `${state.matchPreparation.teamName} is 1st pick` : 'Radiant is 1st pick'
    const option2 = state.matchPreparation.teamId !== -1 ? `${state.matchPreparation.teamName} is 2nd pick` : 'Dire is 1st pick'
    return html`
      <div>
        <select class="constraint-select" name=${index} onchange=${constraintPickOrderChanged}>
          <option value="-1">Any pick order</option>
          <option value="0" selected=${constraint.constraintData.order === 0}>${option1}</option>
          <option value="1" selected=${constraint.constraintData.order === 1}>${option2}</option>
        </select>
      </div>
    `
  }

  const constraintPickOrderChanged = event => {
    if(event.target.value) {
      const newOrder = parseInt(event.target.value)
      const constraintIndex = parseInt(event.target.name)
      const constraint = { constraintData: { order: newOrder } }
      emit(state.events.changeConstraint, [constraint, constraintIndex])
    }
  }

  const getConstraintMatchTimeSelector = (constraint, index) => {
    return html`
    <div>
      <select class="constraint-select" name=${index} onchange=${constraintMatchTimeChanged}>
        <option value="-1">All matches</option>
        <option value="3" selected=${constraint.constraintData.time === 3}>Last year</option>
        <option value="2" selected=${constraint.constraintData.time === 2}>Last 3 months</option>
        <option value="1" selected=${constraint.constraintData.time === 1}>Last month</option>
        <option value="0" selected=${constraint.constraintData.time === 0}>Last week</option>
      </select>
    </div>
    `
  }

  const constraintMatchTimeChanged = event => {
    if(event.target.value) {
      const newTime = parseInt(event.target.value)
      const constraintIndex = parseInt(event.target.name)
      const constraint = { constraintData: { time: newTime } }
      emit(state.events.changeConstraint, [constraint, constraintIndex])
    }
  }

  const getConstraintHeroSelector = (constraint, index) => {
    let sideOptions = []
    if(state.matchPreparation.teamId !== -1) {
      sideOptions.push(html`<option value=${constraintTypes.team.heroInMatch} selected=${constraint.constraintType === constraintTypes.team.heroInMatch}>By either team</option>`)
      sideOptions.push(html`<option value=${constraintTypes.team.withHero} selected=${constraint.constraintType === constraintTypes.team.withHero}>By ${state.matchPreparation.teamName}</option>`)
      sideOptions.push(html`<option value=${constraintTypes.team.againstHero} selected=${constraint.constraintType === constraintTypes.team.againstHero}>By opponent</option>`)
    } else {
      sideOptions.push(html`<option value=${constraintTypes.league.withHeroInMatch} selected=${constraint.constraintType === constraintTypes.league.withHeroInMatch}>By either team</option>`)
      sideOptions.push(html`<option value=${constraintTypes.league.withHeroRadiant} selected=${constraint.constraintType === constraintTypes.league.withHeroRadiant}>By radiant</option>`)
      sideOptions.push(html`<option value=${constraintTypes.league.withHeroDire} selected=${constraint.constraintType === constraintTypes.league.withHeroDire}>By dire</option>`)
    }
    return html`
      <div>
        <select class="constraint-select" name=${index} onchange=${constraintHeroChanged}>
          <option value="-1">Any hero</option>
          ${heroData.map(hero => html`<option value=${hero.hero_id} selected=${constraint.constraintData.heroId === hero.hero_id}>${hero.localized_name}</option>`)}
        </select>
        <select class="constraint-select" name=${index} onchange=${constraintHeroPickOrBanChanged}>
          <option value="-1" selected=${constraint.constraintData.pickType === -1}>Picked or banned</option>
          <option value="0" selected=${constraint.constraintData.pickType === 0}>Picked</option>
          <option value="1" selected=${constraint.constraintData.pickType === 1}>Banned</option>
        </select>
        <select class="constraint-select" name=${index} onchange=${constraintHeroTeamChanged}>
          ${sideOptions}
        </select>
        <select class="constraint-select" name=${index} onchange=${constraintHeroPhaseChanged}>
        <option value="-1" selected=${constraint.constraintData.pickPhase === -1}>Any phase</option>
          <option value="0" selected=${constraint.constraintData.pickPhase === 0}>1st phase</option>
          <option value="1" selected=${constraint.constraintData.pickPhase === 1}>2nd phase</option>
          <option value="2" selected=${constraint.constraintData.pickPhase === 2}>3rd phase</option>
        </select>
      </div>
    `
  }

  const getConstraintResultSelector = (constraint, index) => {
    const radiantText = state.matchPreparation.teamId !== -1 ? `${state.matchPreparation.teamName} won` : 'Radiant won'
    const direText = state.matchPreparation.teamId !== -1 ? `${state.matchPreparation.teamName} lost` : 'Dire won'

    return html`
      <div>
        <select class="constraint-select" name=${index} onchange=${constraintResultChanged}>
          <option value="-1">Any result</option>
          <option value="0" selected=${constraint.constraintData.result === 0}>${radiantText}</option>
          <option value="1" selected=${constraint.constraintData.result === 1}>${direText}</option>
        </select>
      </div>
    `
  }

  const constraintResultChanged = event => {
    if (event.target.value) {
      const resultId = parseInt(event.target.value)
      const constraintIndex = parseInt(event.target.name)
      const constraint = { constraintData: { result: resultId } }
      emit(state.events.changeConstraint, [constraint, constraintIndex])
    }
  }

  const constraintTeamChanged = event => {
    if (event.target.value) {
      const teamId = parseInt(event.target.value)
      const constraintIndex = parseInt(event.target.name)
      const constraint = { constraintData: { teamId: teamId } }
      emit(state.events.changeConstraint, [constraint, constraintIndex])
    }
  }

  const constraintHeroChanged = event => {
    if (event.target.value) {
      const heroId = parseInt(event.target.value)
      const constraintIndex = parseInt(event.target.name)
      const constraint = { constraintData: { heroId: heroId } }
      emit(state.events.changeConstraint, [constraint, constraintIndex])
    }
  }

  const constraintHeroTeamChanged = event => {
    const type = parseInt(event.target.value)
    const constraintIndex = parseInt(event.target.name)
    const constraint = { constraintType: type }
    emit(state.events.changeConstraint, [constraint, constraintIndex])
  }

  const constraintHeroPickOrBanChanged = event => {
    const pickType = parseInt(event.target.value)
    const constraintIndex = parseInt(event.target.name)
    const constraint = { constraintData: { pickType: pickType } }
    emit(state.events.changeConstraint, [constraint, constraintIndex])
  }

  const constraintHeroPhaseChanged = event => {
    const phase = parseInt(event.target.value)
    const constraintIndex = parseInt(event.target.name)
    const constraint = {constraintData: {pickPhase: phase}}
    emit(state.events.changeConstraint, [constraint, constraintIndex])
  }

  const getRemovalIcon = (constraint, constraintIndex) => {
    const style = constraint.permanent ? 'dn' : ''
    return html`
      <a class="fr ${style}" name=${constraintIndex} onclick=${removeConstraint}><i class="icon-cancel fr" ></i></a>
    `
  }

  const removeConstraint = event => {
    const constraintIndex = event.target.parentNode.name
    if (constraintIndex) {
      emit(state.events.removeConstraint, constraintIndex)
    }
  }

  const getConstraintContent = (constraint, index) => {
    if (!constraint.active) return getConstraintAdder()
    else {
      let constraintOptions
      if(state.matchPreparation.teamId !== -1) {
        switch (constraint.constraintType) {
          case constraintTypes.team.heroInMatch:
          case constraintTypes.team.againstHero:
          case constraintTypes.team.withHero:
            constraintOptions = getConstraintHeroSelector(constraint, index)
            break
          case constraintTypes.team.againstTeam:
            constraintOptions = getConstraintTeamSelector(constraint, index)
            break
          case constraintTypes.team.atEvent:
            constraintOptions = getConstraintEventSelector(constraint, index)
            break
          case constraintTypes.team.onPatch:
            constraintOptions = getConstraintPatchSelector(constraint, index)
            break
          case constraintTypes.team.onSide:
            constraintOptions = getConstraintSideSelector(constraint, index)
            break
          case constraintTypes.team.result:
            constraintOptions = getConstraintResultSelector(constraint, index)
            break
          case constraintTypes.team.pickOrder:
            constraintOptions = getConstraintPickOrderSelector(constraint, index)
            break
          case constraintTypes.team.matchTime:
            constraintOptions = getConstraintMatchTimeSelector(constraint, index)
            break
        }
      } else if(state.matchPreparation.leagueId !== -1) {
        switch (constraint.constraintType) {
          case constraintTypes.league.withHeroDire:
          case constraintTypes.league.withHeroRadiant:
          case constraintTypes.league.withHeroInMatch:
            constraintOptions = getConstraintHeroSelector(constraint, index)
            break
          case constraintTypes.league.includesTeam:
            constraintOptions = getConstraintTeamSelector(constraint, index)
            break
          case constraintTypes.league.onPatch:
            constraintOptions = getConstraintPatchSelector(constraint, index)
            break
          case constraintTypes.league.pickOrder:
            constraintOptions = getConstraintPickOrderSelector(constraint, index)
            break
          case constraintTypes.league.result:
            constraintOptions = getConstraintResultSelector(constraint, index)
            break
        }
      }
      
      return html`
        <div class="constraint-box">
          ${constraintOptions}
          ${getRemovalIcon(constraint, index)}
        </div
      `
    }
  }

  const getConstraintBox = (constraint, index) => {
    return html`
      <div>
        ${getConstraintContent(constraint, index)}
      </div>
    `
  }

  return html`
    <div>
      <h2 class="f4">Filters</h2>
      ${state.matchPreparation.constraints.map((constraint, index) => getConstraintBox(constraint, index))}
    </div>
  `
}