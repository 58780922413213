const Component = require('choo/component')
const html = require('choo/html')
const HeroRow = require('./heroRow')
const TextInput = require('../components/textInput')
const heroTypes = require('../utilities/draftData').heroTypes
const heroData = require('../utilities/heroData')
const strengthHeroes = heroData.filter(hero => hero.primary_attr === heroTypes.strength)
const agilityHeroes = heroData.filter(hero => hero.primary_attr === heroTypes.agility)
const intelligenceHeroes = heroData.filter(hero => hero.primary_attr === heroTypes.intelligence)

class HeroList extends Component {
  constructor(id, state, emit) {
    super(id)
    this.state = state
    this.emit = emit
  }

  createElement() {
    return html`
      <div class="hero-list fl w-60">
        ${this.state.cache(TextInput, 'hero-filter').render('Hero filter', this.state.draftPractice.filterText, this.state.events.filterTextChange)}
        ${this.state.cache(HeroRow, 'hero-row-strength').render('STRENGTH', strengthHeroes)}
        ${this.state.cache(HeroRow, 'hero-row-agility').render('AGILITY', agilityHeroes)}
        ${this.state.cache(HeroRow, 'hero-row-intelligence').render('INTELLIGENCE', intelligenceHeroes)}
      </div>
    `
  }

  update() {
    this.state.cache(TextInput, 'hero-filter').render('Hero filter', this.state.draftPractice.filterText, this.state.events.filterTextChange)
    this.state.cache(HeroRow, 'hero-row-strength').render('STRENGTH', strengthHeroes)
    this.state.cache(HeroRow, 'hero-row-agility').render('AGILITY', agilityHeroes)
    this.state.cache(HeroRow, 'hero-row-intelligence').render('INTELLIGENCE', intelligenceHeroes)
    return false
  }
}

module.exports = HeroList