module.exports = {
  maxBonusTime: 130,
  pickTimes: {
    pick: 30,
    ban: 30
  },
  heroTypes: {
    strength: 0,
    agility: 1,
    intelligence: 2
  },
  pickOrBan:
  [
    0,
    0,
    0,
    0,
    1,
    1,
    1,
    1,
    0,
    0,
    0,
    0,
    0,
    0,
    1,
    1,
    1,
    1,
    0,
    0,
    0,
    0,
    1,
    1
  ],
  draftOrder:
  [
    0,
    1,
    0,
    1,
    0,
    1,
    1,
    0,
    0,
    1,
    0,
    1,
    0,
    1,
    1,
    0,
    0,
    1,
    0,
    1,
    0,
    1,
    0,
    1
  ]
}