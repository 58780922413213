const html = require('choo/html')
const TextInput = require('../components/textInput')
const heroData = require('../utilities/heroData')
const HeroList = require('../components/heroList')
const heroUtil = require('../utilities/heroUtil')
const matchDisplay = require('../utilities/matchDisplay')
const draftData = require('../utilities/draftData')
const Header = require('../components/header')
const Footer = require('../components/footer')

const draftSettings = {
  radiantStarts: true,
  userIsRadiant: true
}

const title = 'Draft Practice'

module.exports = (state, emit) => {
  if (state.title !== title) emit(state.events.DOMTITLECHANGE, title)
  //if (state.general.teamList.length === 0) emit(state.events.fetchNewTeamList)

  const connectToRoom = (event) => {
    emit(state.events.connectToRoom)
  }

  const disconnectFromRoom = (event) => {
    emit(state.events.disconnectFromRoom)
  }

  const generatePickBox = (heroId, isPick, order) => {
    const imageStyle = isPick ? 'draft-pick' : 'draft-ban'

    return html`
      <div class=${imageStyle}>
        <span class="pick-order-text">${order + 1}</span>
        <img src=${heroUtil.getHeroUrl(heroId)} />
      </div>
    `
  }

  const radiantIsPicking = _ => {
    const pickTeam = draftData.draftOrder[state.draftPractice.picks.length]
    return (pickTeam === 0 && state.draftPractice.radiantStarts) || (pickTeam === 1 && !state.draftPractice.radiantStarts)
  }

  const teamIsPicking = (team) => {
    const draftIsOver = state.draftPractice.picks.length === draftData.pickOrBan.length
    const teamDraftNum = (state.draftPractice.radiantStarts && team === 0) || (!state.draftPractice.radiantStarts && team === 1) ? 0 : 1
    return !draftIsOver && draftData.draftOrder[state.draftPractice.picks.length] === teamDraftNum
  }

  const getTeamTime = team => {
    const extraTime = team === 0 ? state.draftPractice.radiantExtraTime : state.draftPractice.direExtraTime
    const draftIsOver = state.draftPractice.picks.length === draftData.pickOrBan.length
    const divStyle = state.draftPractice.timerOn && !draftIsOver ? 'pb2 flex items-center' : 'dn'
    const teamDraftNum = (state.draftPractice.radiantStarts && team === 0) || (!state.draftPractice.radiantStarts && team === 1) ? 0 : 1
    const teamIsPicking = !draftIsOver && draftData.draftOrder[state.draftPractice.picks.length] === teamDraftNum
    const pickTimeStyle = teamIsPicking ? 'f5' : 'f5 not-visible'
    return html`
      <div class=${divStyle}>
        <span><i class="icon-stopwatch"></i></span>
        <div class="ml2">
          <span class=${pickTimeStyle}>${state.draftPractice.currentPickTime}</span><br>
          <span class="f5">${extraTime}</span>
        </div>
      </div>
    `
  }

  const selectHero = event => {
    const heroId = parseInt(event.target.name)
    const order = state.draftPractice.picks.length
    const userIsPicking = !state.draftPractice.online || (teamIsPicking(state.draftPractice.userIsRadiant ? 0 : 1)) 
    if (userIsPicking && !heroHasBeenPicked(heroId) && order < draftData.draftOrder.length && heroIsInCm(heroId)) {
      emit(state.events.selectHero, heroId)
    }
  }

  const heroIsInCm = heroId => {
    const hero = heroData.find(hero => hero.hero_id === heroId)
    return hero && hero.in_cm
  }

  const timerToggle = event => {
    emit(state.events.timerToggle)
  }

  const pauseToggle = event => {
    emit(state.events.pauseToggle)
  }

  const radiantStartsToggle = event => {
    draftSettings.radiantStarts = event.target.checked
  }

  const restartDraft = event => {
    emit(state.events.restartDraft, draftSettings)
  }

  const undoLastPick = event => {
    emit(state.events.undoLastPick)
  }

  const generatePickColumn = isRadiant => {
    const radiantExt = !state.draftPractice.online || !state.draftPractice.userIsRadiant ? '' : '(ME)'
    const direExt = !state.draftPractice.online || state.draftPractice.userIsRadiant ? '' : '(ME)'
    const draftIsOver = state.draftPractice.picks.length === draftData.pickOrBan.length
    const heading = isRadiant ? `RADIANT ${radiantExt}` : `DIRE ${direExt}`
    const teamNum = isRadiant ? 0 : 1
    const radiantPicking = radiantIsPicking()
    const order = state.draftPractice.picks.length
    const pickingIconStyle = draftData.pickOrBan[order] === 1 ? 'icon-user-plus' : 'icon-user-times'
    const pickingIcon = !draftIsOver && ((radiantPicking && isRadiant) || (!radiantPicking && !isRadiant)) ? html`<span class="f5"><i class="${pickingIconStyle}"></i></span>` : html`<span class="f5 not-visible"><i class="${pickingIconStyle}"></i></span>`

    return html`
      <div class="fl w-100 w-50-ns">
        <div class="flex flex-column items-start mb1">
          <span class="f5 b pv2">${heading}</span>
          ${pickingIcon}
        </div>
        
        <div class="pick-column">
          ${getTeamTime(teamNum)}
          <div class="dt dt--fixed">
            <div class="dtc v-top">
              ${state.draftPractice.picks.filter(pick => pick.team === teamNum && !pick.is_pick).map(teamPick => {
                return generatePickBox(teamPick.hero_id, teamPick.is_pick, teamPick.order)
              })}
            </div>
            <div class="dtc v-top">
              ${state.draftPractice.picks.filter(pick => pick.team === teamNum && pick.is_pick).map(teamPick => {
                return generatePickBox(teamPick.hero_id, teamPick.is_pick, teamPick.order)
              })}
            </div>
          </div>
        </div>
      </div>
    `
  }

  const radiantTeamChanged = event => {
    if (event.target.value) {
      const teamId = parseInt(event.target.value)
      emit(state.events.radiantTeamChanged, teamId)
    }
  }

  const direTeamChanged = event => {
    if (event.target.value) {
      const teamId = parseInt(event.target.value)
      emit(state.events.direTeamChanged, teamId)
    }
  }
  
  const getOptions = _ => {
    const displayPause = state.draftPractice.timerOn ? 'flex items-center' : 'dn'
    return html`
      <div class="fl w-100">
        <span class="f4 b">Options</span>
        <div class="flex items-center mb1">
          <div class="flex items-center">
            <input class="mr2" type="checkbox" id="timer" checked=${state.draftPractice.timerOn} onchange=${timerToggle}>
            <label for="timer" class="f5 mr2">Timer</label>
          </div>
          <div class=${displayPause}>
            <input class="mr2" type="checkbox" id="pause" checked=${state.draftPractice.paused} onchange=${pauseToggle}>
            <label for="pause" class="f5 mr2">Pause</label>
          </div>>
        </div>
        <div class="flex items-center mb1">
          <input class="mr2" type="checkbox" id="radiantStarts" checked=${draftSettings.radiantStarts} onchange=${radiantStartsToggle}>
          <label for="radiantStarts" class="f5">Radiant 1st pick (restart draft to change)</label>
        </div>
        <div class="flex">
          <button class="f6 ph1 ph1-ns ph2-m ph2-l pv1 pv1-ns pv2-m pv2-l mr1 mb1 dib black bg-white" onclick=${restartDraft}>Restart draft</button>
          <button class="f6 ph1 ph1-ns ph2-m ph2-l pv1 pv1-ns pv2-m pv2-l mb1 dib black bg-white" onclick=${undoLastPick}>Undo last pick</button>
        </div>
      </div>
    `
  }

  
  const pickHero = (event) => {
    const order = state.draftPractice.picks.length
    if (state.draftPractice.selectedHeroId !== -1 && order < draftData.draftOrder.length) {
      const radiantNum = state.draftPractice.radiantStarts ? 0 : 1
      const teamNum = draftData.draftOrder[order] === radiantNum ? 0 : 1
      const pick = {
        hero_id: state.draftPractice.selectedHeroId,
        is_pick: draftData.pickOrBan[order] === 1,
        order: order,
        team: teamNum
      }
      
      emit(state.events.pickHero, pick)
    }
  }

  const getCurrentHeroSelection = _ => {
    const userIsPicking = !state.draftPractice.online || teamIsPicking(state.draftPractice.userIsRadiant ? 0 : 1)
    const notVisible = state.draftPractice.selectedHeroId === -1 || (state.draftPractice.timerOn && state.draftPractice.paused) || !userIsPicking
    const order = state.draftPractice.picks.length
    const pickText = draftData.pickOrBan[order] === 1 ? 'PICK' : 'BAN'
    const hideStyle = notVisible ? 'dn' : ''
    return html`
      <div class=${hideStyle}>
        <div class="fl w-100 flex items-center">
          <img class="selected-hero" src=${heroUtil.getHeroUrl(state.draftPractice.selectedHeroId)} />
          <button class="select-hero-btn ttu f7 f6-ns f5-m f5-l" onclick=${pickHero}>${`${pickText} ${heroUtil.getHeroName(state.draftPractice.selectedHeroId)}`}</button>
        </div>
      </div>
    `
  }

  const heroHasBeenPicked = heroId => {
    return state.draftPractice.picks.findIndex(pick => pick.hero_id === heroId) !== -1
  }

  const getDraftDescription = () => {
    if(!state.draftPractice.online) {
      return 'Offline practice mode'
    } else {
      const usersConnected = state.draftPractice.draftObject.usersConnected
      const name = state.draftPractice.draftObject.roomName
      return `Room: ${name}, drafters connected: ${usersConnected}`
    }
  }

  const getRoomConnectionDiv = () => {
    const connectionText = state.draftPractice.online ? 'Disconnect' : 'Create/Join a room'
    const buttonOnClick = state.draftPractice.online ? disconnectFromRoom : connectToRoom
    return html`
      <div class="pb2 mb2 bb b--washed-green">
        ${state.cache(TextInput, 'room-key').render('Room key', state.draftPractice.roomName, state.events.roomKeyTextChange)}
        <button class="f6 ph1 ph1-ns ph3-m ph3-l pv1 pv1-ns pv2-m pv2-l mb2 dib black bg-white ml2" onclick=${buttonOnClick}>${connectionText}</button>
      </div>
    `
  }

  const getRelevantStatsToCurrentPhase = (radiant) => {

  }

  const getRecentMatchList = (radiant) => {
    const matchList = radiant ? state.draftPractice.radiantRecentMatches : state.draftPractice.direRecentMatches
    return html`
      <div class="dt tl w-50 f5">
        <div class="dt-row">
          <span class="dtc pa2 v-mid">Match Info</span>
          <span class="dn dtc-l pa2 v-mid">League</span>
          <span class="dtc pa2 v-mid">Radiant</span>
          <span class="dtc pa2 v-mid">Dire</span>
          <span class="dn dtc-l pa2 v-mid">Score</span>
        </div>
        ${matchList.slice(0, 20).map(match => matchDisplay.getMatchRow(match))}
      </div>
    `
  }

  const errorClass = state.draftPractice.errorText ? 'f6 light-red' : 'dn'

  /*
  <div class="flex flex-column items-start mb1">
    <span class="f4 b">Options</span>
    <select class="constraint-select" onchange=${radiantTeamChanged}>
      <option value="-1">Radiant team</option>
      ${state.general.teamList.map(team => 
        html`<option value=${team.team_id} selected=${state.draftPractice.radiantTeamId == team.team_id}>${team.name}</option>`
      )}
    </select>
    <select class="constraint-select" onchange=${direTeamChanged}>
      <option value="-1">Dire team</option>
      ${state.general.teamList.map(team => 
        html`<option value=${team.team_id} selected=${state.draftPractice.direTeamId == team.team_id}>${team.name}</option>`
      )}
    </select>
  </div>

  */
  return html`
    <body class="bg-near-black pv1 ph1 ph2-ns ph3-m ph3-l">
      ${state.cache(Header, 'header').render()}
      <div class="fl w-100 pa4-l pa3-m pa1-ns">
        <h1 class="f4">${getDraftDescription()}</h1>
        <p class="f6">
          Draft against yourself in offline mode. If you create or join a room, you can only pick and ban when it is your turn.
        </p>
        <p class=${errorClass}>
          ${state.draftPractice.errorText}
        </p>
        ${getRoomConnectionDiv()}
        <div class="fl w-100">
          ${state.cache(HeroList, 'hero-list').render()}
          <div class="fl w-40 pl2">
            ${getOptions()}
            <div class="fl w-100">
              ${generatePickColumn(true)}
              ${generatePickColumn(false)}
            </div>
            ${getCurrentHeroSelection()}
          </div>
        </div>
      </div>
      ${state.cache(Footer, 'footer').render()}
    </body>
  `
/*
<div class="mb2 bb b--washed-green">
  <div class="fl w-100">
    <div class="flex">
      ${getRecentMatchList(true)}
      ${getRecentMatchList(false)}
    </div>
    ${getRelevantStatsToCurrentPhase(true)}
    ${getRelevantStatsToCurrentPhase(false)}
  </div>
</div>*/
}