const html = require('choo/html')
const matches = require('./matchList')
const filters = require('./matchFilters')
const stats = require('./matchStatistics')
const teamSelector = require('./teamSelector')
const Header = require('../components/header')
const Footer = require('../components/footer')

const title = 'Matches'

module.exports = function (state, emit) {
  if (state.title !== title) emit(state.events.DOMTITLECHANGE, title)
  if (state.general.teamList.length === 0 && !state.general.teamListQueued) emit(state.events.fetchNewTeamList)
  if (state.general.leagueList.length === 0 && !state.general.leagueListQueued) emit(state.events.fetchNewLeagueList)
  
  const errorClass = state.matchPreparation.errorText ? 'f4 light-red' : 'dn'
  const showMatchList = state.matchPreparation.teamId !== -1 || state.matchPreparation.leagueId !== -1 ? '' : 'dn'
  return html`
    <body class="bg-near-black pv1 ph1 ph2-ns ph3-m ph3-l cf">
      ${state.cache(Header, 'header').render()}
      <div class="fl w-100 pa4-l pa3-m pa1-ns">
        ${teamSelector(state, emit)}
        <h2 class=${errorClass}>${state.matchPreparation.errorText}</h2>
        <div class=${showMatchList}>
          ${filters(state, emit)}
          ${stats(state, emit)}
          ${matches(state, emit)}
        </div>
      </div>
      ${state.cache(Footer, 'footer').render()}
    </body>
  `
}
