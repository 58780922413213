const Component = require('choo/component')
const html = require('choo/html')

class Header extends Component {
  constructor(id) {
    super(id)
  }

  createElement() {
    return html`
      <header class="fl w-100 ph4-l ph3-m ph1-ns pv3 pv4-ns top">
        <nav class="f6 fw6 ttu tracked">
          <a class="link dim washed-green dib mr3" href="/">Home</a>
          <a class="link dim washed-green dib mr3" href="/mapdrawing">Map Drawing</a>
          <a class="link dim washed-green dib mr3" href="/draft">Draft Practice</a>
          <a class="link dim washed-green dib mr3" href="/matches">Matches</a>
        </nav>
      </header>
    `
  }

  update() {
    return false
  }
}

module.exports = Header