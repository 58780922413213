const Component = require('choo/component')
const html = require('choo/html')

class ScoreboardRow extends Component {
  constructor(id, state, emit) {
    super(id)
    this.state = state
    this.emit = emit
  }

  createElement(playerSlot, mode) {
    const replay = this.state.match.replay
    if (replay.matchId) {
      const snapshot = replay.snapshots[this.state.match.time]
      const hasTP = snapshot.players[playerSlot].hasTP
      const neutralItemName = snapshot.players[playerSlot].neutralItem
      const itemMap = replay.itemMap
      
      switch (mode) {
        case 0:
          return html`
            <div class="w-100 flex items-center">
              <div class="w-third hero hero-${replay.heroIds[playerSlot]} relative mr2">
                ${hasTP ? html`<img alt=${itemMap["tpscroll"]} title=${itemMap["tpscroll"]} class="tp-item" src="/assets/images/items/tpscroll.png" />`: null}
              </div>
              <div class="w-two-thirds flex items-center">
                <div>
                  ${snapshot.players[playerSlot].items.slice(0, 6).map(itemName => {
                    return html`
                      <img alt=${itemMap[itemName]} title=${itemMap[itemName]} class="scoreboard-item dib" src="/assets/images/items/${itemName}.png" />
                    `
                  })}
                </div>
                <div>
                  <img alt=${itemMap[neutralItemName]} title=${itemMap[neutralItemName]} class="scoreboard-item" src="/assets/images/items/${neutralItemName}.png" />
                </div>
              </div>
            </div>
          `
        case 1:
          return html`
            <div class="w-100 flex items-center">
              <div class="w-third hero hero-${replay.heroIds[playerSlot]} mr2"></div>
              <div class="w-two-thirds flex">
                <p class="mr2">LVL: ${snapshot.players[playerSlot].level}</p>
                <p class="mr2">K/D/A: ${snapshot.players[playerSlot].kills}/${snapshot.players[playerSlot].deaths}/${snapshot.players[playerSlot].assists}</p>
                <p>LH: ${snapshot.players[playerSlot].lastHits} DN: ${snapshot.players[playerSlot].denies}</p>
              </div>
            </div>
          `
        case 2:
          return html`
            <div class="w-100 flex items-center">
              <div class="w-third hero hero-${replay.heroIds[playerSlot]} mr2"></div>
              <div class="w-two-thirds">
                <p class="mr2">NW: ${snapshot.players[playerSlot].netWorth}</p>
              </div>
            </div>
          `
      }
    } else {
      return html`
        <div class="w-100 flex items-center>
          
        </div>
      `
    }
  }

  update(playerSlot) {
    return true
  }
}

module.exports = ScoreboardRow