const Component = require('choo/component')
const html = require('choo/html')

class GraphTooltip extends Component {
  constructor(id, state, emit) {
    super(id)
    this.state = state
    this.emit = emit
    this.visible = false
  }

  createElement(visible, time, gold, xp, x) {
    this.visible = visible
    if(!visible) {
      return html`<div class="absolute dn"></div>`
    }

    time = time - 90

    let minutes = Math.floor(Math.abs(time) / 60)
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    if (time < 0) {
      minutes = `-${minutes}`
    }
    
    let seconds = Math.abs(time) % 60
    if (seconds < 10) {
      seconds = `0${seconds}`
    }
    const timeDisplay = `${minutes}:${seconds}`

    return html`
      <div class="ba b--washed-green bg-near-black absolute f5 pa2" style="left:${x}px;top:${30}px;z-index:1;pointer-events:none;">
        <p>Time: ${timeDisplay}</p>
        <p>Gold: ${gold}</p>
        <p>XP: ${xp}</p>
      </div>
    `
  }

  update(visible, time, gold, xp) {
    return true
  }
}

module.exports = GraphTooltip