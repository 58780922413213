const html = require('choo/html')
const heroUtil = require('./heroUtil')

const getMatchDuration = (matchData) => {
  if (matchData.duration) {
    let minutes = Math.floor(matchData.duration / 60)
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    let seconds = matchData.duration % 60
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return minutes + ':' + seconds
  } else {
    return 'Unknown'
  }
}

const getMatchStartTime = matchData => {
  if (matchData.start_time) {
    const gapSeconds = Date.now() / 1000 - (matchData.start_time + matchData.duration)
    const gapMinutes = gapSeconds / 60
    const gapHours = gapMinutes / 60
    const gapDays = gapHours / 24
    const gapMonths = gapDays / 30
    const gapYears = gapDays / 365
    if (gapYears > 1 && gapYears < 2) {
      return '1 year ago'
    } else if (gapYears > 2) {
      return `${Math.floor(gapYears)} years ago`
    } else {
      if (gapMonths > 1 && gapMonths < 2) {
        return '1 month ago'
      } else if (gapMonths > 2) {
        return `${Math.floor(gapMonths)} months ago`
      } else {
        if (gapDays > 1 && gapDays < 2) {
          return '1 day ago'
        } else if (gapDays > 2) {
          return `${Math.floor(gapDays)} days ago`
        } else {
          if (gapHours > 1 && gapHours < 2) {
            return '1 hour ago'
          } else if (gapHours > 2) {
            return `${Math.floor(gapHours)} hours ago`
          } else {
            return `${Math.round(gapMinutes)} minutes ago`
          }
        }
      }
    }
  } else {
    return ''
  }
}

const generatePickBox = (heroId, isPick, order) => {
  const imageStyle = isPick ? 'pick-box' : 'ban-box'
  return html`
    <div class=${imageStyle}>
      <span class="f7 f5-ns f5-m f5-l pick-order-text">${order + 1}</span>
      <img src=${heroUtil.getHeroUrl(heroId)} />
    </div>
  `
}

const getMatchRow = (matchData) => {

  const matchLink = `/match/${matchData.match_id}`
  let matchDisplay
  if (matchData.replay_available) {
    matchDisplay = html`<a class="link dim blue underline db" href="${matchLink}">${matchData.match_id}</a>`
  } else {
    matchDisplay = html`<span class="db">${matchData.match_id}</span>`
  }
  const radiantTextStyle = matchData.radiant_win ? "text-won" : "text-lost"
  const direTextStyle = matchData.radiant_win ? "text-lost" : "text-won"
  const showRadiantTrophy = matchData.radiant_win ? 'mr1' : 'dn'
  const showDireTrophy = matchData.radiant_win ? 'dn' : 'mr1'
  return html`
    <div class="dt-row">
      <div class="dtc pa2 v-mid f6 f5-ns f5-m f5-l">
          ${matchDisplay}
          <span class="db">${getMatchStartTime(matchData)}</span>
          <span class="db">${matchData.patch}</span>
      </div>
      <div class="dn dtc-l pa2 v-mid f6 f5-ns f5-m f5-l"><span>${matchData.league_name}</span></div>
      <div class="dtc tl pa2 v-mid f6 f5-ns f5-m f5-l">
          <span class=${showRadiantTrophy}><i class="icon-award"></i></span><span class=${radiantTextStyle}>${matchData.radiant_team_name}</span><br>
          ${matchData.picksandbans.filter(pick => pick.team === 0 && pick.is_pick).map(filteredPick => {
            return generatePickBox(filteredPick.hero_id, filteredPick.is_pick, filteredPick.ord)
          })}
          <br>
          ${matchData.picksandbans.filter(pick => pick.team === 0 && !pick.is_pick).map(filteredPick => {
            return generatePickBox(filteredPick.hero_id, filteredPick.is_pick, filteredPick.ord)
          })}
      </div>
      <div class="dtc tl pa2 v-mid f6 f5-ns f5-m f5-l">
          <span class=${showDireTrophy}><i class="icon-award"></i></span><span class=${direTextStyle}>${matchData.dire_team_name}</span><br>
          ${matchData.picksandbans.filter(pick => pick.team === 1 && pick.is_pick).map(filteredPick => {
            return generatePickBox(filteredPick.hero_id, filteredPick.is_pick, filteredPick.ord)
          })}
          <br>
          ${matchData.picksandbans.filter(pick => pick.team === 1 && !pick.is_pick).map(filteredPick => {
            return generatePickBox(filteredPick.hero_id, filteredPick.is_pick, filteredPick.ord)
          })}
      </div>
      <div class="dn dtc-l pa2 v-mid f6 f5-ns f5-m f5-l">
        <span>${matchData.radiant_score} - ${matchData.dire_score}</span><br>
        <span>${getMatchDuration(matchData)}</span>
      </div>
    </div>
  `
}

const generateHeroInfoTooltip = (heroId, matchData) => {
  return html`
    <div class="relative bg-near-black pa2 dt">
      <div class="dt-row">
        Test
      </div>
      <div class="dt-row">
        Test2
      </div>
      <div class="dt-row">
        Test3
      </div>
    </div>
  `
}

const generateStatRowInfoBox = (infoText) => {

  return html`

  `
}

const generateHeroInfoBox = (heroId, matchData, cb) => {
  const result = cb(heroId, matchData)

  return html`

  `
}

module.exports = {
  getMatchDuration: getMatchDuration,
  getMatchStartTime: getMatchStartTime,
  getMatchRow: getMatchRow,
  generateHeroInfoTooltip: generateHeroInfoTooltip,
  generatePickBox: generatePickBox
}